import { denormalize } from "normalizr";
import {
  ClusterProfileSchema,
  ClusterProfileTemplateSchema,
  ManifestSchema,
  PackVersionSchema,
} from "utils/schemas";

function extractManifests(pack) {
  const packManifests = pack?.manifests?.length > 0 && pack?.manifests;
  return packManifests || pack.spec.manifests || pack?.pack?.manifests || [];
}

function presentPacks(profiles) {
  return profiles
    .filter((profile) => profile.type !== "system")
    .reduce(
      (acc, profile) => [
        ...acc,
        ...profile.spec.published.packs.map((pack) => ({
          ...pack,
          profileGuid: profile.guid,
          manifests: extractManifests(pack),
        })),
      ],
      []
    );
}

function getPackValues(values, packGuid) {
  return values?.[packGuid] || "";
}

const PackChangeSchema = [
  {
    current: {
      pack: PackVersionSchema,
      profile: ClusterProfileTemplateSchema,
    },
    target: {
      pack: PackVersionSchema,
      profile: ClusterProfileSchema,
    },
    manifests: [
      {
        current: {
          pack: PackVersionSchema,
          profile: ClusterProfileTemplateSchema,
          manifest: ManifestSchema,
        },
        target: {
          pack: PackVersionSchema,
          profile: ClusterProfileSchema,
          manifest: ManifestSchema,
        },
      },
    ],
  },
];

export const profileDifferentiator = {
  groupChange(changes) {
    return changes.reduce((accumulator, packChange) => {
      const profile = !packChange.target
        ? packChange?.current?.profile
        : packChange?.target?.profile;

      const profileName =
        profile?.metadata?.name || profile?.profile?.metadata?.name;

      const existingProfile = accumulator.find(
        ({ profile }) =>
          profile?.metadata?.name === profileName ||
          profile?.profile?.metadata?.name === profileName
      );

      if (existingProfile) {
        existingProfile.packs.push(packChange);
      } else {
        accumulator.push({
          profile: {
            ...profile,
            metadata: profile?.metadata || profile?.profile?.metadata,
            spec: profile?.spec || profile?.profile?.spec,
          },
          packs: [packChange],
        });
      }

      return accumulator;
    }, []);
  },
  getPackChanges: ({
    currentProfiles,
    targetProfiles,
    values,
    entities,
    ChangeSchema = PackChangeSchema,
  }) => {
    const currentPacks = presentPacks(currentProfiles);
    const targetPacks = presentPacks(targetProfiles);
    const changes = [];

    const filteredTargetPacks = targetPacks.filter((targetPack) => {
      const existingPack = currentPacks.find(
        (initialPack) =>
          initialPack.spec.name === targetPack.spec.name &&
          initialPack.profileGuid === targetPack.profileGuid
      );

      return !existingPack;
    });

    filteredTargetPacks?.forEach((targetPack) => {
      const currentPack = currentPacks.find(
        (initialPack) => initialPack.spec.name === targetPack.spec.name
      );
      const targetPackValues = getPackValues(values, targetPack.guid);
      if (!currentPack) {
        changes.push({
          type: "pack-added",
          current: null,
          target: {
            pack: targetPack.guid,
            values: targetPackValues,
            profile: targetPack.profileGuid,
          },
          manifests: targetPack.manifests.map((manifest) => ({
            type: "manifest-added",
            current: null,
            target: {
              pack: targetPack.guid,
              manifest: manifest.guid,
            },
          })),
        });
      }

      if (currentPack) {
        const packChanges = [];
        const currentManifests = currentPack?.manifests || [];
        const targetManifests = targetPack?.manifests || [];
        const manifestChanges = [];

        targetManifests.forEach((targetManifest) => {
          const currentManifest = currentManifests.find(
            (initialManifest) => initialManifest.name === targetManifest.name
          );
          const targetManifestValues = getPackValues(
            values,
            targetManifest.guid
          );

          if (!currentManifest) {
            manifestChanges.push({
              type: "manifest-added",
              current: null,
              target: {
                pack: targetPack.guid,
                manifest: targetManifest?.guid,
              },
            });
          }

          if (currentManifest) {
            const currentManifestValues = getPackValues(
              values,
              currentManifest.guid
            );

            if (currentManifestValues !== targetManifestValues) {
              manifestChanges.push({
                type: "manifest-updated",
                current: {
                  pack: currentPack?.guid,
                  manifest: currentManifest?.guid,
                  values: currentManifestValues,
                  profile: currentPack.profileGuid,
                },
                target: {
                  pack: targetPack.guid,
                  manifest: targetManifest?.guid,
                  values: targetManifestValues,
                  profile: targetPack.profileGuid,
                },
              });
            }

            // unknown change state
            if (
              (!currentManifestValues || !targetManifestValues) &&
              currentManifest?.guid !== targetManifest?.guid
            ) {
              manifestChanges.push({
                type: "unknown",
                current: {
                  pack: currentPack?.guid,
                  manifest: currentManifest?.guid,
                  profile: currentPack.profileGuid,
                  manifestName: currentManifest?.name,
                },
                target: {
                  pack: targetPack.guid,
                  manifest: targetManifest?.guid,
                  profile: targetPack.profileGuid,
                  manifestName: targetManifest?.name,
                },
              });
            }
          }
        });

        currentManifests.forEach((currentManifest) => {
          const targetManifest = targetManifests.find(
            (targetManifest) => targetManifest.name === currentManifest.name
          );

          if (!targetManifest) {
            manifestChanges.push({
              type: "manifest-removed",
              current: {
                pack: currentPack?.guid,
                manifest: currentManifest?.guid,
              },
              target: null,
            });
          }
        });

        if (
          targetPackValues &&
          targetPackValues.trim() !== currentPack?.spec?.values.trim()
        ) {
          packChanges.push("values");
        }

        if (targetPack?.spec?.version !== currentPack?.spec?.version) {
          packChanges.push("version");
        }

        if (manifestChanges.length) {
          packChanges.push("children");
        }

        if (packChanges.length)
          changes.push({
            type: "pack-updated",
            current: {
              pack: currentPack.guid,
              values: currentPack.spec.values,
              profile: currentPack.profileGuid,
            },
            target: {
              pack: targetPack.guid,
              values: targetPackValues,
              profile: targetPack.profileGuid,
            },
            manifests: manifestChanges,
            changes: packChanges,
          });
      }
    });

    currentPacks?.forEach((currentPack) => {
      const targetPack = targetPacks.find(
        (initialPack) => initialPack.spec.name === currentPack.spec.name
      );

      if (!targetPack) {
        changes.push({
          type: "pack-removed",
          current: {
            pack: currentPack.guid,
            values: currentPack.spec.values,
            profile: currentPack.profileGuid,
          },
          target: null,
          manifests: currentPack.manifests.map((manifest) => ({
            type: "manifest-removed",
            current: {
              manifest: manifest.guid,
              pack: currentPack.guid,
              profile: currentPack.profileGuid,
            },
            target: null,
          })),
        });
      }
    });

    return {
      changes,
      get denormalized() {
        return denormalize(changes, ChangeSchema, entities);
      },
      get unknownManifests() {
        const unknownManifestsRaw = changes
          .flatMap((change) => change.manifests)
          .filter((change) => change.type === "unknown");
        return denormalize(
          unknownManifestsRaw,
          ChangeSchema[0].manifests,
          entities
        );
      },
      get groupedChanges() {
        return profileDifferentiator.groupChange(this.denormalized);
      },

      get updatesOnly() {
        const filteredChanges = changes
          .filter(
            (packChange) =>
              packChange.type === "pack-updated" &&
              (packChange?.changes.includes("values") ||
                packChange?.manifests.some(
                  (manifestChange) => manifestChange.type === "manifest-updated"
                ))
          )
          .map((packChange) => ({
            ...packChange,
            manifests: packChange.manifests.filter(
              (manifestChange) => manifestChange.type === "manifest-updated"
            ),
          }));

        return profileDifferentiator.groupChange(
          denormalize(filteredChanges, ChangeSchema, entities)
        );
      },
    };
  },
};
