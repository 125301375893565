import i18n from "i18next";
import axios from "axios";
import history from "services/history";

import api from "services/api";
import notifications from "services/notifications";

import { awsAccountFormActions } from "./aws";
import { vmWareAccountFormActions } from "./vmware";
import { azureAccountFormActions } from "./azure";
import { googleAccountFormActions } from "./google";
import { maasAccountFormActions } from "./maas";
import { openstackAccountFormActions } from "./openstack";
import { tencentAccountFormActions } from "./tencent";
import { coxedgeAccountFormActions } from "./coxedge";

import {
  CLOUD_ACCOUNTS_MODULE,
  cloudAccountFormModal,
  cloudAccountKeysFetcher,
  selfHostedOverlordsFetcher,
} from "state/cloudaccounts/services";
import {
  getAwsCloudAccountPayload,
  getSelectedCloudAccount,
} from "state/cloudaccounts/selectors";
import { cloudAccountsSummaryFetcher } from "state/cluster/actions/list/clusters";
import { MANAGED_TO_PURE_ENVIRONMENT } from "utils/constants";
import clusterFormActions from "state/cluster/actions/create/form";
import { cloudAccountsFetcher } from "state/cluster/services/create";
import appEnv from "services/app";
import { customAccountFormActions } from "./custom";

// TODO: for the validator, the common part should be extracted in a validator,
// the cloud specific fields should be used as a generator
// the same idea of having a mapping could be applied
// the validator should be under the services directory
export const cloudTypesFormActions = {
  aws: awsAccountFormActions,
  vsphere: vmWareAccountFormActions,
  azure: azureAccountFormActions,
  gcp: googleAccountFormActions,
  maas: maasAccountFormActions,
  openstack: openstackAccountFormActions,
  tencent: tencentAccountFormActions,
  coxedge: coxedgeAccountFormActions,
  custom: customAccountFormActions,
};

export function unsetSelectedCloudAccount() {
  return (dispatch) => {
    dispatch({
      type: "UNSET_CLOUD_ACCOUNT",
    });
  };
}

export function openCloudAccountTypeModal() {
  return async (dispatch, getState) => {
    const query = history.getQuery();
    const { preselectedProfile, cloudType, profileType } =
      getState().forms?.cluster?.data;
    dispatch(openAccountModalByType(query.cloudType));
    if (query.cloudType) {
      dispatch(onCloudAccountTypeSelect(query.cloudType));
      return;
    }
    if (preselectedProfile && profileType !== "add-on") {
      dispatch(onCloudAccountTypeSelect(cloudType));
    }
  };
}

export function initCloudAccountForm(env) {
  return async function thunk(dispatch, getState) {
    const isCustom = appEnv.isCustomCloud(env);

    if (isCustom) {
      await dispatch(cloudAccountKeysFetcher.key(env).fetch());
      dispatch(selfHostedOverlordsFetcher.fetch());
      dispatch(
        cloudTypesFormActions.custom.init({
          module: CLOUD_ACCOUNTS_MODULE,
          env,
        })
      );
      return;
    }

    dispatch(
      cloudTypesFormActions[env].init({
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );
  };
}

export function submitCloudAccountForm(env) {
  return async function thunk(dispatch, getState) {
    const isCustom = appEnv.isCustomCloud(env);

    if (isCustom) {
      return dispatch(
        cloudTypesFormActions.custom.submit({
          module: CLOUD_ACCOUNTS_MODULE,
        })
      );
    }

    return dispatch(
      cloudTypesFormActions[env].submit({
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );
  };
}

export function onCloudAccountTypeSelect(value) {
  return (dispatch) => {
    const environment = MANAGED_TO_PURE_ENVIRONMENT[value]
      ? MANAGED_TO_PURE_ENVIRONMENT[value]
      : value;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "accountCloudType",
        value,
      })
    );

    dispatch(openAccountModalByType(environment));
    dispatch(initCloudAccountForm(environment));
  };
}

export function openAccountModalByType(cloudType) {
  return async (dispatch, getState) => {
    const cancelToken = axios.CancelToken.source();
    const { profileType } = getState().forms?.cluster?.data;

    cloudAccountFormModal.open({ cloudType, cancelToken }).then(
      async () => {
        await dispatch(submitCloudAccountForm(cloudType));
        const accountsFetcher =
          profileType === "add-on"
            ? cloudAccountsSummaryFetcher
            : cloudAccountsFetcher;
        dispatch(accountsFetcher.fetch());
        dispatch(
          clusterFormActions.onChange({
            module: "cluster",
            name: "accountCloudType",
            value: null,
          })
        );
      },
      () => {
        dispatch(
          clusterFormActions.onChange({
            module: "cluster",
            name: "accountCloudType",
            value: null,
          })
        );
        cancelToken.cancel();
      }
    );
  };
}

export function openAccountModal({
  cloudType,
  uid,
  viewMode,
  reloadCloudAccounts = false,
}) {
  return async (dispatch) => {
    const cancelToken = axios.CancelToken.source();

    const environment = MANAGED_TO_PURE_ENVIRONMENT[cloudType]
      ? MANAGED_TO_PURE_ENVIRONMENT[cloudType]
      : cloudType;

    cloudAccountFormModal
      .open({ cloudType: environment, uid, cancelToken, viewMode })
      .then(
        async () => {
          await dispatch(submitCloudAccountForm(environment));

          if (reloadCloudAccounts) {
            dispatch(cloudAccountsSummaryFetcher.fetch());
            return;
          }
          history.push("/settings/cloudaccounts");

          return;
        },
        () => {
          cancelToken.cancel();
          if (reloadCloudAccounts) {
            return;
          }
          history.push("/settings/cloudaccounts");
        }
      );

    dispatch(initCloudAccountForm(environment));
  };
}

export function getGCPPayloadData({ jsonCredentials, jsonFile }) {
  if (jsonCredentials) {
    return {
      jsonCredentials: jsonCredentials.trim(),
    };
  }
  return {
    jsonCredentialsFileUid: jsonFile?.response?.uid,
  };
}

export function validateAccount() {
  return async function (dispatch, getState) {
    const data = getState().forms?.[CLOUD_ACCOUNTS_MODULE]?.data;
    const cloudType = cloudAccountFormModal?.data?.cloudType;

    if (!data) {
      return;
    }

    const endpoint = {
      aws: "v1/clouds/aws/account/validate",
      azure: "v1/clouds/azure/account/validate",
      gcp: "v1/clouds/gcp/account/validate",
      tencent: `v1/clouds/tencent/account/validate`,
      maas: `v1/overlords/maas/${data.overlordUid}/account/validate`,
      vsphere: `v1/overlords/vsphere/${data.overlordUid}/account/validate`,
      openstack: `v1/overlords/openstack/${data.overlordUid}/account/validate`,
      coxedge: "v1/clouds/coxedge/account/validate",
    };
    const payload = {
      aws: getAwsCloudAccountPayload(getState()),
      vsphere: {
        account: {
          vcenterServer: data.vcenterServer,
          username: data.username,
          password: data.password,
          insecure: data.insecure,
        },
      },
      azure: {
        tenantId: data.tenantId?.trim(),
        clientId: data.clientId?.trim(),
        clientSecret: data.clientSecret?.trim(),
        tenantName: data.tenantName?.trim(),
        azureEnvironment: data.partition?.trim(),
      },
      gcp: {
        spec: getGCPPayloadData(data),
      },
      maas: {
        account: {
          apiEndpoint: data.apiEndpoint?.trim(),
          apiKey: data.apiKey?.trim(),
        },
      },
      openstack: {
        account: {
          caCert: data.caCert?.trim(),
          defaultDomain: data.defaultDomain,
          identityEndpoint: data.identityEndpoint,
          insecure: !!data.insecure,
          parentRegion: data.parentRegion,
          password: data.password,
          username: data.username,
          defaultProject: data.defaultProject,
        },
      },
      tencent: {
        secretKey: data.secretKey,
        secretId: data.secretId,
      },
      coxedge: {
        apiKey: data.apiKey,
        service: data.service,
        environment: data.environment,
        apiBaseUrl: data.apiBaseUrl,
        organizationId: data.organizationId,
      },
    };

    const errors = await dispatch(
      cloudTypesFormActions[cloudType].validateForm({
        module: CLOUD_ACCOUNTS_MODULE,
      })
    );
    if (errors.length === 0) {
      const promise = api.post(endpoint[cloudType], payload[cloudType], {
        cancelToken: cloudAccountFormModal.data?.cancelToken?.token,
      });
      dispatch({
        type: "VALIDATE_CLOUD_ACCOUNT",
        data,
        promise,
      });
      try {
        await promise;
      } catch (err) {
        notifications.error({
          message: i18n.t("We couldn't validate your account details"),
          description: err.message,
        });
      }
    }
  };
}

export function createAccount(cloudType, payload) {
  return async (dispatch, getState) => {
    const selectedCloudAccount = getSelectedCloudAccount(getState());

    try {
      selectedCloudAccount
        ? await api.put(
            `v1/cloudaccounts/${cloudType}/${selectedCloudAccount.metadata.uid}`,
            payload
          )
        : await api.post(`v1/cloudaccounts/${cloudType}`, payload);
    } catch (error) {
      const message = selectedCloudAccount
        ? i18n.t("Something went wrong when editing the account")
        : i18n.t("Something went wrong when creating the account");

      notifications.error({
        message,
        description: error.message,
      });

      return Promise.reject();
    }
  };
}

export function onChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: CLOUD_ACCOUNTS_MODULE };
    const isAccountValidated = getState().cloudaccounts.form.isValidated;

    if (isAccountValidated) {
      if (name !== "password") {
        dispatch({
          type: "FORM_BATCH_UPDATE",
          updates: {
            password: "",
            clientSecret: "",
            secretKey: "",
            apiKey: "",
          },
          module: CLOUD_ACCOUNTS_MODULE,
        });
      }
      dispatch({
        type: "SET_ACCOUNT_AS_INVALID",
      });
    }

    dispatch({
      type: "FORM_ON_CHANGE",
      ...props,
    });
  };
}
