import i18n from "i18next";
import moment from "moment";
import axios from "axios";

import dataFetcher from "modules/dataFetcher";
import ListActions from "modules/list/actions";

import api, { nonProjectApi } from "services/api";
import notifications from "services/notifications";
import store from "services/store";
import history from "services/history";
import { EventSchema, PackVersionSchema } from "utils/schemas";
import ProfileStackModule from "modules/profileStack";
import createFormActions from "modules/form/actions";
import {
  getCluster,
  getRawCluster,
  getUpdateNotification,
  getUpdateNotificationProfiles,
} from "../selectors/details";
import { getPackValuesWithoutPresetsComment, getBoolean } from "utils/parsers";
import { getCurrentProjectUidFromUrl } from "state/auth/selectors";
import { GENERAL_DATE_FORMAT } from "utils/constants";
import ModalService from "services/modal";
import { getCurrentContext } from "state/auth/selectors/common";

//

export const clusterUpgradesModalService = new ModalService("clusterUpgrades");
export const EDIT_CLUSTER_SIZE_FORM_MODULE = "editClusterSize";

export const gaugeFetcher = dataFetcher({
  selectors: ["clusters", "metrics", "gauges"],
  async fetchData(_, clusterId, query, includeMasters = false) {
    try {
      const relativeUrl = `v1/metrics/spectrocluster/${clusterId}/values?`;
      const result = await api.get(relativeUrl, query);
      if (!result) {
        return;
      }

      let data = {
        cpuRequest: result.items?.find((item) => item.kind === "cpuRequest"),
        cpuTotal: result.items?.find((item) => item.kind === "cpuTotal"),
        memoryRequest: result.items?.find(
          (item) => item.kind === "memoryRequest"
        ),
        memoryTotal: result.items?.find((item) => item.kind === "memoryTotal"),
      };

      if (includeMasters) {
        const aggregatedResult = await api.get(relativeUrl, {
          ...query,
          includeMasterMachines: true,
        });
        if (!aggregatedResult) {
          return;
        }

        data = {
          ...data,
          masters: {
            cpuRequest: aggregatedResult.items?.find(
              (item) => item.kind === "cpuRequest"
            ),
            cpuTotal: aggregatedResult.items?.find(
              (item) => item.kind === "cpuTotal"
            ),
            memoryRequest: aggregatedResult.items?.find(
              (item) => item.kind === "memoryRequest"
            ),
            memoryTotal: aggregatedResult.items?.find(
              (item) => item.kind === "memoryTotal"
            ),
          },
        };
      }

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the cluster requests metrics"
        ),
        description: error?.message,
      });
    }
  },
});

export const utilizationFetcher = dataFetcher({
  selectors: ["clusters", "metrics", "utilization"],
  async fetchData(_, resourceUid, query, resourceKind = "spectrocluster") {
    if (!resourceKind || !resourceUid) {
      return;
    }
    try {
      const result = await api.get(
        `v1/metrics/${resourceKind}/${encodeURIComponent(resourceUid)}/values`,
        query
      );
      return {
        cpuUsage: result.items?.find((item) => item.kind === "cpuUsage"),
        cpuTotal: result.items?.find((item) => item.kind === "cpuTotal"),
        memoryUsage: result.items?.find((item) => item.kind === "memoryUsage"),
        memoryTotal: result.items?.find((item) => item.kind === "memoryTotal"),
        period: query?.period,
      };
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get cluster utilization data"
        ),
        description: err?.message,
      });
    }
  },
});

export const clusterCertificatesFetcher = dataFetcher({
  selectors: ["clusters", "certificates"],
  async fetchData() {
    try {
      const clusterUid = store.getState().location.params.id;
      const result = await api.get(
        `v1/spectroclusters/${clusterUid}/k8certificates`
      );

      return { items: result.machineCertificates };
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get this cluster's certificates"
        ),
        description: err?.message,
      });
    }
  },
});

function fetchClusterEvents(query) {
  const clusterUid = store.getState().location.params.id;
  let apiPath = `v1/events/components/spectrocluster/${clusterUid}`;

  if (query.component && query.component !== clusterUid) {
    apiPath = `v1/events/components/edgehost/${query.component}`;
  }

  function buildFilters() {
    const filters = [];

    if (query.status && query.status.length > 0) {
      filters.push(`severity=${query.status.join("ORseverity=")}`);
    }

    if (query.interval && query.interval !== "all-time") {
      const [time, unit] = query.interval.split(" ");
      if (time && unit) {
        const startTime = moment().subtract(time, unit).utc().format();
        filters.push(`metadata.creationTimestamp>${startTime}`);
      }
    }

    return filters.join("AND");
  }

  return api.get(apiPath, {
    continue: query.continue,
    limit: query.limit,
    filters: buildFilters(),
  });
}

export const clusterErrorFetcher = dataFetcher({
  selectors: ["clusterDetails", "events", "error"],
  fetchData(_, query) {
    return fetchClusterEvents(query);
  },
});

export const eventListActions = new ListActions({
  schema: [EventSchema],
  initialQuery() {
    const cluster = getRawCluster(store.getState());

    const defaultFilters = {
      limit: 100,
      interval: "all-time",
      status: [],
      component: cluster?.metadata?.uid,
    };

    try {
      if (!history.location.search) {
        return defaultFilters;
      }
      let filterString = history.location.search.split("filters=")[1];
      let eventHighlightId = "";

      if (filterString.includes("uid=")) {
        const queryArray = filterString.split("&uid=");
        filterString = queryArray[0];
        eventHighlightId = queryArray[1];
      }

      const initialFilters = JSON.parse(decodeURIComponent(filterString));
      return {
        ...initialFilters,
        eventHighlightId,
        limit: 50,
      };
    } catch (err) {
      return defaultFilters;
    }
  },
  fetchData(query) {
    return fetchClusterEvents(query);
  },
});

export const profileModule = new ProfileStackModule({
  name: "cluster-details",
});

export const updatesFormAction = createFormActions({
  async init() {
    const cluster = getRawCluster(store.getState());
    const notification = getUpdateNotification(store.getState());

    if (!notification) {
      return;
    }

    store.dispatch({
      type: "SET_CLUSTER_PACK_DIVERGENCES",
      value: {},
    });

    const listOfPacks = new Set();
    const promises = notification?.events.reduce((acc, event) => {
      if (
        [
          "PackValuesUpdate",
          "PackVersionUpdate",
          "PackUpdate",
          "PackManifestUpdate",
          "PackManifestDelete",
          "PackManifestCreate",
          "PackCreate",
        ].includes(event.type) &&
        !listOfPacks.has(event.packName)
      ) {
        const promise = store.dispatch({
          promise: api.get(
            `v1/spectroclusters/${cluster.metadata.uid}/packs/${event.packName}/config`
          ),
          type: "FETCH_CLUSTER_PACK_DIVERGENCE_INFO",
          packName: event.packName,
          profileUid: event.profileUid,
          schema: {
            items: [
              {
                spec: PackVersionSchema,
              },
            ],
          },
        });
        listOfPacks.add(event.packName);
        acc.push(promise);
      }

      return acc;
    }, []);

    await Promise.allSettled(promises);
    const profilesPayload = profileModule.payload;
    const divergenceInfo =
      store.getState().cluster.details.notification.divergences;

    const updatedProfilesPayload = profilesPayload.map((profilePayload) => {
      const tempProfilePayload = { ...profilePayload };

      tempProfilePayload.packValues = profilePayload.packValues.map((pack) => {
        const divergenceInfoPackItems =
          divergenceInfo?.[profilePayload.uid]?.[pack.name]?.items;
        if (divergenceInfoPackItems) {
          return {
            ...pack,
            manifests: divergenceInfoPackItems[1]?.spec?.manifests,
            version: divergenceInfoPackItems[1]?.spec?.version,
            values: divergenceInfoPackItems[1]?.spec?.values,
          };
        }
        return { ...pack, tag: pack?.version || pack?.tag };
      });

      const divergenceInfoPacks = divergenceInfo?.[profilePayload.uid];
      Object.keys(divergenceInfoPacks || {}).forEach((pack) => {
        if (!tempProfilePayload.packValues.find((pVal) => pVal.name === pack)) {
          tempProfilePayload.packValues.push({
            name: pack,
            manifests: divergenceInfoPacks[pack]?.items[0]?.spec?.manifests,
            version: divergenceInfoPacks[pack]?.items[0]?.spec?.version,
            values: divergenceInfoPacks[pack]?.items[0]?.spec?.values,
            type: divergenceInfoPacks[pack]?.items[0]?.spec?.type,
          });
        }
      });

      return tempProfilePayload;
    });

    await store.dispatch(
      clusterRepaveFetcher.fetch({ profiles: updatedProfilesPayload })
    );

    const profiles = getUpdateNotificationProfiles(store.getState());
    return profiles.reduce((accumulator, profile) => {
      profile.spec.packs.forEach((pack) => {
        const [clusterInfo, profileInfo] =
          divergenceInfo[profile.metadata.uid]?.[pack.metadata.name]?.items ||
          [];
        if (pack?.event?.type === "PackCreate") {
          accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
            clusterInfo.spec?.values
          );
        }

        if (!clusterInfo?.spec?.isValuesOverridden) {
          if (profileInfo) {
            accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
              profileInfo?.spec?.values
            );
          }
        } else {
          accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
            clusterInfo.spec?.values
          );
        }
      });

      return accumulator;
    }, {});
  },
});

export const lastClusterModificationFetcher = dataFetcher({
  selectors: ["clusterDetails", "lastModification"],
  fetchData: async () => {
    const state = store.getState();
    const clusterCategory = state.location?.params?.clusterCategory;
    if (clusterCategory === "privatecloudgateways") {
      return Promise.resolve(null);
    }

    const projectUid = getCurrentProjectUidFromUrl(state);
    const cluster = getCluster(state);

    const data = await api.get("v1/audits", {
      projectUid,
      resourceKind: "spectrocluster",
      resourceUid: cluster.metadata.uid,
      limit: 1,
    });

    return data?.items?.length > 0 ? data.items[0] : null;
  },
});

export const namespaceConsumptionFetcher = dataFetcher({
  selectors: [
    "namespaceConsumption",
    (state) => getRawCluster(state)?.metadata?.uid,
    (state) => state.cluster.details.includeMasters,
  ],
  fetchData(
    [_, clusterUid, includeMasters],
    { startTime, endTime, period } = {}
  ) {
    if (!startTime || !endTime) {
      return;
    }
    const payload = {
      filter: {
        startTime,
        endTime,
        includeMasterMachines: getBoolean(includeMasters),
      },
      options: {
        period: period || 60 * 24,
      },
    };

    return api.post(
      `v1/dashboard/spectroclusters/${clusterUid}/resources/consumption`,
      payload
    );
  },
});

export const realTimeConsumptionFetcher = dataFetcher({
  selectors: [
    "realTimeConsumption",
    (state) => state?.cluster?.details?.includeMasters,
  ],
  fetchData([_, includeMasters], clusterUid) {
    const startTime = moment()
      .subtract(1, "h")
      .utc()
      .format(GENERAL_DATE_FORMAT);
    const endTime = moment().utc().format(GENERAL_DATE_FORMAT);

    const payload = {
      filter: {
        startTime,
        endTime,
        includeMasterMachines: getBoolean(includeMasters),
      },
      options: {
        period: 0,
      },
    };

    return api.post(
      `v1/dashboard/spectroclusters/${clusterUid}/resources/consumption`,
      payload
    );
  },
});

export function fetchRealTimeConsumptionData(clusterUid) {
  return (dispatch) => {
    dispatch(realTimeConsumptionFetcher.fetch(clusterUid));
  };
}

export const tencentSshKeyPairsFetcher = dataFetcher({
  selectors: ["tke", "ssh"],
  async fetchData(_, { region, cloudAccountUid }) {
    try {
      const response = await api.get(
        `v1/clouds/tencent/regions/${region}/keypairs?cloudAccountUid=${cloudAccountUid}`
      );
      return response?.keypairs || [];
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong when trying to get the SSH keys"),
        description: err?.message,
      });
    }
  },
});

export const kubectlRedirectUriFetcher = dataFetcher({
  selectors: ["kubectl", "redirectUri"],
  async fetchData(_, { clusterUid, projectUid }) {
    try {
      const response = await api.get(
        `v1/spectroclusters/${clusterUid}/kubectl/redirect`,
        {
          ProjectUid: projectUid,
        }
      );
      return response;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to get the redirect URI"
        ),
        description: err?.message,
      });
    }
  },
});

export const getContextCurrentLock = dataFetcher({
  selectors: ["clusterlock"],
  async fetchData() {
    const response = await api.get("v1/spectroclusters/upgrade/settings");
    return response?.spectroComponents;
  },
});

export const hostClusterFetcher = dataFetcher({
  selectors: [
    "hostCluster",
    (state) => getRawCluster(state)?.status?.virtual?.hostCluster?.uid,
  ],
  fetchData([_1, hostClusterUid]) {
    const isAdminView = getCurrentContext(store.getState())?.isAdmin;
    const path = `v1/spectroclusters/${hostClusterUid}`;

    return isAdminView ? nonProjectApi.get(path) : api.get(path);
  },
});

export const overlordsFetcher = dataFetcher({
  selectors: ["privatecloudgateway"],
  async fetchData() {
    const data = await nonProjectApi.get(`v1/overlords`);

    return data.items;
  },
});

export const availableClusterFiltersFetcher = dataFetcher({
  selectors: ["availableClusterFilters"],
  fetchData() {
    return api.get("v1/dashboard/spectroclusters/metadata/search/schema");
  },
});

export const clusterFiltersOptionsFetcher = dataFetcher({
  selectors: ["clusterFiltersOptionsFetcher"],
  fetchData() {
    return api.get("v1/dashboard/spectroclusters/search/input");
  },
});

export const clusterRepaveFetcher = dataFetcher({
  selectors: ["clusterRepaveFetcher"],
  async fetchData(_, { profiles }) {
    const state = store.getState();
    const cluster = getCluster(state);
    try {
      const data = await api.post(
        `v1/spectroclusters/${cluster.metadata.uid}/validate/repave`,
        {
          profiles: profiles.map((profile) => ({
            ...profile,
            packValues: profile.packValues.map((pack) => ({
              ...pack,
              tag: pack.version,
            })),
          })),
        }
      );
      return data;
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong when trying to validate repave"),
        description: err?.message,
      });
    }
  },
});

export const clusterRepaveStatusFetcher = dataFetcher({
  selectors: ["clusterRepaveStatusFetcher"],
  async fetchData(_, { uid, projectUid }) {
    try {
      return await api.get(`v1/spectroclusters/${uid}/repave/status`, null, {
        headers: { ProjectUid: projectUid },
      });
    } catch (err) {
      if (err.code !== "ResourceNotFound") {
        notifications.error({
          message: i18n.t(
            "Something went wrong when trying to get the repave status"
          ),
          description: err?.message,
        });
      }
    }
  },
});
