import React, { useMemo } from "react";
import LayerForm from "modules/profileIDE/connectors/form";
import EditorLayout from "modules/profileIDE/components/Editor/layout";
import RadioGroup from "components/ui/RadioGroup";
import { createConnector } from "modules/binder";
import { connectField, ReadOnlyProvider } from "modules/form";
import Forms from "modules/profileIDE/components/Forms";
import Switch from "components/common/Switch";
import Editor from "components/common/Editor";
import { EditorToolbar } from "components/common/Editor/Toolbar";
import Icon from "components/ui/Icon";
import styled, { css } from "styled-components";
import { faCode } from "@fortawesome/pro-regular-svg-icons";
import FormInputIcon from "assets/icons/form_input.svg?component";
import DeleteFileButton from "./DeleteFileButton";
import { createSelector } from "reselect";
import { appProfileMacrosFetcher } from "modules/profileIDE/state/editor/services";
import store from "services/store";
import { PermissionService } from "services/permissions";
import { useTranslation } from "react-i18next";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import PackReadMe from "modules/profileBuilder/blocks/components/PackReadMe";

const Group = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 3px;
    margin: 0px 8px;
  }
`;
const TabWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px 5px 8px;
  gap: 8px;
  cursor: pointer;
  border: 1px solid #dadee3;

  &:first-child {
    border-width: 1px 0px 1px 1px;
    border-radius: 3px 0px 0px 3px;
  }

  &:last-child {
    border-width: 1px 1px 1px 0;
    border-radius: 0px 3px 3px 0px;
  }

  svg {
    font-size: 17px;
  }

  ${(props) =>
    props.selected &&
    css`
      background: #818ea1;
      border-color: transparent;
      color: #fff;
    `}
`;

const Readme = styled(PackReadMe)`
  > div {
    padding: 0;
    height: 100%;
  }
`;

const HeaderContent = styled.div`
  margin: 0 20px;
`;

const EditorToggle = createConnector({
  selectors: {
    value: (state) => state.editor.mode,
    fileType: (state) => {
      const activeFile = state.fileList.activeFile;
      return state.fileList.entities?.[activeFile]?.type || "";
    },
    hasReadme: (state) => {
      const activeFile = state.fileList.activeFile;
      return state.fileList.entities?.[activeFile]?.readme;
    },
  },
  actions: {
    onChange: (value) => (dispatch, module) => {
      dispatch({
        type: module.actions.editor.switchMode,
        mode: value,
      });
    },
  },
}).connect(({ value, fileType, hasReadme, onChange }) => {
  const { t } = useTranslation();

  const TABS = useMemo(
    () => [
      {
        key: "form",
        value: "form",
        title: t("Properties"),
        icon: { component: FormInputIcon },
        hidden: fileType === "child-manifest",
      },
      {
        key: "readme",
        value: "readme",
        title: t("Readme"),
        icon: { awesome: faFileLines },
        hidden: !hasReadme,
      },
      {
        key: "editor",
        value: "editor",
        title: t("Values"),
        icon: { awesome: faCode },
        hidden: ["operator-instance", "manifest", "container"].includes(
          fileType
        ),
      },
    ],
    [fileType, hasReadme, t]
  );

  function renderTab(tab) {
    const { value, icon, key, disabled, hidden, title } = tab;
    if (hidden) {
      return null;
    }

    return (
      <TabWrap disabled={disabled} key={key} value={value}>
        <Icon {...icon} />
        {title}
      </TabWrap>
    );
  }
  return (
    <Group>
      <RadioGroup data-qa="editor-tabs" value={value} onChange={onChange}>
        {TABS.map(renderTab)}
      </RadioGroup>
    </Group>
  );
});

const ConnectedEditor = connectField(Editor);
const ConnectedReadme = createConnector({
  selectors: {
    readme: (state) => {
      const activeFile = state.fileList.activeFile;
      return state.fileList.entities?.[activeFile]?.readme || "";
    },
  },
}).connect(({ readme }) => (
  <Readme markdownContent={readme} hasToolbar={false} />
));

const EditorWrap = styled.div`
  width: calc(100% + 48px);
  height: calc(100% + 48px);
  margin: -24px;
  display: flex;
  flex-direction: row;

  ${EditorToolbar} {
    display: none;
  }
`;

// This WILL need to be moved to exception due to the fact that it would be able to be used for cluster profiles also
const EditorContent = createConnector({
  selectors: {
    mode: (state) => state.editor.mode,
    macros: createSelector(
      () => appProfileMacrosFetcher.selector(store.getState())?.result,
      (state) => state.drafts,
      (state) => state.fileList.files,
      (state) => state.fileList.childrenMap,
      (state) => state.fileList.activeFile,
      (macros, drafts, files, childrenMap, activeFile) => {
        const filteredMacros = (macros || []).filter(
          (macro) => !macro.includes("#")
        );

        const outputParameterDependentMacros = (macros || []).filter((macro) =>
          macro.includes("#tierOutputParam")
        );
        const inputParameterDependentMacros = (macros || []).filter((macro) =>
          macro.includes("#tierInputParam")
        );

        const operatorTiersDraftsGuids = (Object?.keys(drafts) || []).filter(
          (draftGuid) =>
            ["operator-instance", "container"].includes(
              drafts?.[draftGuid]?.type
            )
        );

        const operatorTiersMacros = operatorTiersDraftsGuids.flatMap(
          (operatorDraftGuid) => {
            let activeFileIndex = files.indexOf(activeFile);

            // child file parent index
            if (activeFileIndex === -1) {
              const parentGuid = Object.keys(childrenMap).find((parentGuid) =>
                childrenMap[parentGuid].includes(activeFile)
              );

              activeFileIndex = files.indexOf(parentGuid);
            }

            if (files.indexOf(operatorDraftGuid) < activeFileIndex) {
              return [];
            }

            const operatorDraftData = drafts[operatorDraftGuid];
            const outputParametersMacros = (
              operatorDraftData?.parameters?.outputParameters || []
            ).flatMap((outputParameter) => {
              return outputParameterDependentMacros.map((outputMacro) =>
                outputMacro
                  .replace("#tierName", operatorDraftData.name)
                  .replace("#tierOutputParam", outputParameter.name)
              );
            });

            return outputParametersMacros;
          }
        );

        const currentDraftData = drafts[activeFile];
        const currentTierInputMacros = (
          currentDraftData?.parameters?.inputParameters || []
        ).flatMap((inputParameter) => {
          return inputParameterDependentMacros.map((inputMacro) =>
            inputMacro
              .replace("#tierName", currentDraftData.name)
              .replace("#tierInputParam", inputParameter.name)
          );
        });

        return [
          ...filteredMacros,
          ...currentTierInputMacros,
          ...operatorTiersMacros,
        ];
      }
    ),
    readOnly: (state) => {
      const isEditMode = state.editor?.isEdit;
      const { permissions } = state?.fileList?.metadata?.annotations || {};
      const appProfilePermissions = new PermissionService(
        permissions?.split(",")
      );
      return !appProfilePermissions.has("appProfile.update") && isEditMode;
    },
  },
}).connect(({ mode, macros, readOnly }) => {
  return (
    <ReadOnlyProvider isActive={readOnly}>
      <Switch value={mode}>
        <Switch.Case value="form">
          <Forms />
        </Switch.Case>
        <Switch.Case value="editor">
          <EditorWrap>
            <ConnectedEditor
              name="values"
              macros={macros}
              readOnly={readOnly}
            />
          </EditorWrap>
        </Switch.Case>
        <Switch.Case value="readme">
          <ConnectedReadme />
        </Switch.Case>
      </Switch>
    </ReadOnlyProvider>
  );
});

const EditorHeader = createConnector({
  selectors: {
    fileName: (state) => {
      const activeFile = state.fileList.activeFile;
      return state.fileList.entities?.[activeFile]?.name || "";
    },
  },
}).connect(({ fileName }) => <HeaderContent>{fileName}</HeaderContent>);

export default function AppProfileEditor() {
  return (
    <>
      <EditorLayout />
      <EditorLayout.HeaderName>
        <EditorHeader />
      </EditorLayout.HeaderName>
      <EditorLayout.DeleteFile>
        <DeleteFileButton />
      </EditorLayout.DeleteFile>
      <EditorLayout.HeaderTabs>
        <EditorToggle />
      </EditorLayout.HeaderTabs>
      <EditorLayout.Content>
        <LayerForm>
          <EditorContent />
        </LayerForm>
      </EditorLayout.Content>
    </>
  );
}
