import React from "react";
import { HeaderTag } from "components/common/ClusterProfileCard";
import { lightPurpleGray } from "utils/constants/colors";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

export function TechPreview() {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t(
        "This functionality is currently in the Tech Preview phase, representing an early release for user exploration and feedback"
      )}
    >
      <HeaderTag inline compresed color={lightPurpleGray}>
        {t("Tech Preview")}
      </HeaderTag>
    </Tooltip>
  );
}
