import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import EditorLayout, {
  EditorSlot,
  SidebarSlot,
} from "components/common/layouts/Editor";
import Modal from "components/ui/Modal";
import Editor from "components/common/Editor";
import DocLink from "components/common/DocLink";
import Icon from "components/ui/Icon";

import ProfilesList from "./ProfilesList";
import Button, { TextButton } from "components/ui/Button";
import { blue } from "utils/constants/colors";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import EditorSplitTitle from "components/common/ClusterProfiles/EditorSplitTitle";

const MainWrap = styled.div`
  margin: -24px;
`;

const TitleBanner = styled.div`
  width: 100%;
`;

const ActionsWrap = styled.section`
  display: flex;
  gap: 8px;
`;

const ConfirmText = styled.div`
  font-size: 12px;
`;

const InfoIcon = styled(Icon)`
  color: ${blue};
  font-size: 16px;
  margin-right: 6px;
`;

const FooterWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.withRepave ? "space-between" : "flex-end"};
  padding: 12px;
  border-top: 1px solid #ddd;
`;

function getLayerBannerInfo(layer) {
  return {
    displayName:
      layer?.pack?.spec?.displayName || layer?.pack?.spec?.name || layer?.name,
    tag: layer?.spec?.version || layer?.version || "",
    logoUrl: layer?.spec?.logoUrl || layer?.pack?.spec?.logoUrl,
    addonType:
      layer?.kind ||
      layer?.type ||
      layer?.pack?.spec?.addonType ||
      layer?.pack?.spec?.type,
  };
}

function ClusterPackDiffsModal({
  profilesDiffs,
  onValuesChange,
  selectedLayerGuid,
  selectedProfileGuid,
  modalService,
  isSubmitting = false,
  isRepaveRequired = false,
  readOnly = false,
  modalOpened = false,
  ...rest
}) {
  const { t } = useTranslation();
  const [reviewedLayers, setReviewedLayers] = useState([]);
  const confirmLabel = isRepaveRequired
    ? t("Initiate Repave")
    : t("Apply Changes");

  useEffect(() => {
    if (!modalOpened) {
      setReviewedLayers([]);
    }
  }, [modalOpened]);

  useEffect(() => {
    if (
      !selectedLayerGuid ||
      reviewedLayers.includes(selectedLayerGuid) ||
      !modalOpened
    ) {
      return;
    }

    setReviewedLayers((prevState) => [...prevState, selectedLayerGuid]);
  }, [selectedLayerGuid, reviewedLayers, modalOpened]);

  const allLayers = useMemo(() => {
    const packs = (profilesDiffs || []).flatMap((profile) => profile.packs);
    const manifests = packs.flatMap((profile) => profile.manifests);

    return [...packs, ...manifests];
  }, [profilesDiffs]);

  const selectedLayer = useMemo(() => {
    return allLayers.find(
      ({ current, target }) =>
        target?.manifest?.guid === selectedLayerGuid ||
        current?.manifest?.guid === selectedLayerGuid ||
        target?.pack?.guid === selectedLayerGuid ||
        current?.pack?.guid === selectedLayerGuid
    );
  }, [selectedLayerGuid, allLayers]);

  const editorInfo = useMemo(() => {
    const { current, target } = selectedLayer || {};
    const currentInfo = current?.manifest || current?.pack;
    const targetInfo = target?.manifest || target?.pack;

    return {
      to: {
        ...getLayerBannerInfo(targetInfo),
        helperText: t("Incoming Configuration"),
      },
      from: {
        ...getLayerBannerInfo(currentInfo),
        helperText: t("Running Configuration"),
      },
    };
  }, [t, selectedLayer]);

  function renderEditorTitle() {
    return (
      <TitleBanner>
        <EditorSplitTitle {...editorInfo} />
      </TitleBanner>
    );
  }

  function renderFooterActions() {
    return (
      <>
        {isRepaveRequired && (
          <ConfirmText>
            <InfoIcon awesome={faInfoCircle} />
            {t(
              "Repaving might temporarily affect your cluster's performance or configuration. "
            )}
            <DocLink
              location="full-cluster-repave"
              text={t("Learn more on Repave Behavior and Configuration")}
              fontSize={12}
            />
          </ConfirmText>
        )}
        <ActionsWrap>
          <TextButton
            data-qa="cancel-action"
            onClick={() => {
              modalService.reject();
            }}
          >
            {t("Cancel")}
          </TextButton>
          <Button
            data-qa="continue-review"
            loading={isSubmitting}
            onClick={() => modalService.resolve()}
            disabled={reviewedLayers.length !== allLayers.length}
          >
            {confirmLabel}
          </Button>
        </ActionsWrap>
      </>
    );
  }

  return (
    <Modal
      width="1400px"
      title={t("Review Update Changes")}
      service={modalService}
      footer={null}
    >
      <MainWrap>
        <EditorLayout />
        <SidebarSlot.Source>
          <ProfilesList
            profilesDiffs={profilesDiffs}
            selectedLayer={selectedLayerGuid}
            reviewedLayers={reviewedLayers}
            {...rest}
          />
        </SidebarSlot.Source>
        <EditorSlot.Source>
          <Editor
            value={selectedLayer?.target?.values || ""}
            defaultValues={selectedLayer?.current?.values || ""}
            onChange={onValuesChange}
            language="yaml"
            showDiff
            readOnly={readOnly}
            {...rest}
            title={renderEditorTitle()}
          />
        </EditorSlot.Source>
        <FooterWrap withRepave={isRepaveRequired}>
          {renderFooterActions()}
        </FooterWrap>
      </MainWrap>
    </Modal>
  );
}

export default ClusterPackDiffsModal;
