export const ROOT = "/";

export const AUDIT = {
  ROOT: "/auditlogs",
};

export const AUTH = {
  ROOT: "/auth",
  ERROR: "/auth-error",
  NOT_FOUND: "/404",
  NO_PROJECTS: "/no-projects",
  PASSWORD_ACTION: "/auth/password/:passwordToken/:action(activate|reset)",
  ACTIVATE_PASSWORD: "/auth/password/:passwordToken/activate",
  RESET_PASSWORD: "/auth/password/:passwordToken/reset",
  FORGOT_PASSWORD: "/auth/forgot-password",
  UPDATE_PASSWORD: "/auth/password-update",
  SIGNUP: "/auth/signup",
  ORG_SELECT: "/auth/orgs",
  DEVX_SIGNUP: "/dev/auth/signup",
  OIDC_SUCCESS: "/auth/oidc/success",
  OIDC_LOGIN: "/auth/oidc/:token",
};

const rootPath = "/profiles/:tab(cluster|system)";

export const CLUSTER_PROFILES = {
  ROOT: rootPath,
  CREATE: `${rootPath}/:id(create)`,
  DETAILS: `${rootPath}/:id`,
  CREATE_CLUSTER_PROFILE: `/profiles/:tab(cluster)/:id(create)`,
  CREATE_SYSTEM_PROFILE: `/profiles/:tab(system)/:id(create)`,
};

const clusterCategory = "/:clusterCategory(clusters|privatecloudgateways)";

export const CLUSTERS = {
  ROOT: "/clusters/:tab(overview|appliances|mapview|virtual)",
  MAP_VIEW: "/clusters/mapview",
  APPLIANCES_OVERVIEW: "/clusters/appliances/:id/:tab(overview|events)",
  CREATE_CLUSTER: "/clusters/create",
  NEW_CLUSTER: "/clusters/new",
  CREATE_CLUSTER_WITH_SELECTED_PROFILE: "/clusters/create/:profileUid",
  CLUSTER_DETAILS: "/clusters/:id",
  TAB_DETAILS: `${clusterCategory}/:id/:tab(overview|configure|nodes|workloads|events|backups|restores|scans|costs|appliances|vms)/:flag?`,
  SCAN_DETAILS: `${clusterCategory}/:id/:tab(scans)/:scanType/:scanUid`,
  SCANS: `${clusterCategory}/:id/:tab(scans)`,
  CERTIFICATES: `${clusterCategory}/:id/certificates`,
  IPAM_DNS_DETAILS:
    "/:clusterCategory(privatecloudgateways)/:id/:type/:uid/:tab(ipam|dns)",
  CONFIGURE_LAYER: "/clusters/:id/configure/:layerUid?",
  NODES_FLAG: `${clusterCategory}/:id/:tab(nodes)/:flag?`,
  NODES: `${clusterCategory}/:id/:tab(nodes)`,
  WORKLOADS: `${clusterCategory}/:id/:tab(workloads)/:flag(virtual-clusters|namespaces|pods|deployments|daemonsets|statefulsets|jobs|cronjobs|rolebindings|clusterrolebindings)`,
  EVENTS: `${clusterCategory}/:id/:tab(events)`,
  BACKUPS: `${clusterCategory}/:id/:tab(backups)/:flag(backups|restores)`,
  OVERVIEW: `${clusterCategory}/:id/:tab(overview)`,
  COSTS: `${clusterCategory}/:id/:tab(costs)`,

  NEW_VIRTUAL_CLUSTER: "/clusters/virtual/new",
};

export const MANAGEMENT = {
  ROOT: "/management/:tab(users|teams)",
  USERS: "/management/:tab(users)",
  CREATE_USER: "/management/:tab(users)/create",
  TEAMS: "/management/:tab(teams)",
  CREATE_TEAM: "/management/:tab(teams)/create",
  EDIT_TEAM: "/management/:tab(teams)/edit",
};

export const MY_PROFILE = "/my-profile";
export const MY_API_KEYS = {
  ROOT: "/my-api-keys",
  CREATE: "/my-api-keys/create",
  EDIT: "/my-api-keys/:uid/edit",
};

export const PRIVATE_CLOUD_GATEWAYS = {
  IPAM: "/privatecloudgateways/:type/:uid/ipam",
  CREATE_IPAM: "/privatecloudgateways/:type/:uid/ipam/create",
  IPAM_DNS_TABS: "/privatecloudgateways/:id/:type/:uid/:tab(ipam|dns)",
};

export const PROJECTS = {
  ROOT: "/projects",
  CREATE_PROJECT: "/projects/create",
  EDIT_PROJECT: "/projects/:id/edit",
};

export const ROLES = {
  ROOT: "/roles/:tab(tenant|project|resource|workspace)",
};

export const SETTINGS = {
  ROOT: "/settings",

  CLOUD_ACCOUNTS: "/settings/cloudaccounts",
  CLOUD_ACCOUNT: "/settings/cloudaccounts/:cloudType/:uid",
  CREATE_CLOUD_ACCOUNT: "/settings/cloudaccounts/:cloudType/create",
  EDIT_CLOUD_ACCOUNT: "/settings/cloudaccounts/:cloudType/:uid/edit",
  CREATE_VSPHERE_CLOUD_ACCOUNT:
    "/settings/cloudaccounts/:cloudType(vsphere)/create",
  EDIT_VSPHERE_CLOUD_ACCOUNT:
    "/settings/cloudaccounts/:cloudType(vsphere)/:id/edit",

  SSH_KEYS: "/settings/sshkeys",
  CREATE_SSH_KEY: "/settings/sshkeys/create",
  EDIT_SSH_KEY: "/settings/sshkeys/:uid/edit",

  MAINTENANCE_POLICIES: "/settings/maintenancepolicies",
  CREATE_MAINTENANCE_POLICY: "/settings/maintenancepolicies/create",

  REGISTRIES: "/settings/registries/:tab(pack|helm|oci)",
  CREATE_REGISTRY: "/settings/registries/:tab(pack|helm|oci)/create",
  EDIT_REGISTRY:
    "/settings/registries/:tab(pack|helm|oci)/:uid/:ociType(ecr|basic)?/edit",

  PRIVATE_CLOUD_GATEWAYS: "/settings/privatecloudgateways",
  CREATE_PRIVATE_CLOUD_GATEWAY:
    "/settings/privatecloudgateways/:cloudType/create",
  EDIT_PRIVATE_CLOUD_GATEWAY:
    "/settings/privatecloudgateways/:cloudType/:uid/edit",
  PRIVATE_CLOUD_GATEWAY_NODES:
    "/settings/privatecloudgateways/:cloudType/:uid/set-nodes",
  PRIVATE_CLOUD_GATEWAY_MIGRATE:
    "/settings/privatecloudgateways/:cloudType/:uid/migrate",

  SSO: "/settings/sso/:tab(configure|domains|providers)",
  PASSWORD_POLICY: "/settings/password-policy",
  BILLING_DETAILS: "/settings/billingdetails",
  PLAN_DETAILS: "/settings/plandetails",

  BACKUP_LOCATIONS: "/settings/backuplocations",
  CREATE_BACKUP_LOCATION: "/settings/backuplocations/create",
  EDIT_BACKUP_LOCATION: "/settings/backuplocations/:provider/:uid/edit",

  AUDIT_TRAILS: "/settings/audittrails",
  CREATE_AUDIT_TRAIL: "/settings/audittrails/create",
  EDIT_AUDIT_TRAIL: "/settings/audittrails/:uid/edit",

  ALERTS: "/settings/alerts/:tab(email|webhooks)",
  CREATE_WEBHOOK_ALERT: "/settings/alerts/webhooks/create",
  CLOUD_RATES: "/settings/cloudrates",

  API_KEYS: "/settings/apikeys",
  CREATE_API_KEY: "/settings/apikeys/create",
  EDIT_API_KEY: "/settings/apikeys/:uid/edit",

  MACROS: "/settings/macros",

  RESOURCE_LIMITS: "/settings/resource-limits",

  PLATFORM_SETTINGS: "/settings/platform-settings",

  REGISTRATION_TOKENS: "/settings/registration-tokens",
  CREATE_REGISTRATION_TOKEN: "/settings/registration-tokens/create",
  EDIT_REGISTRATION_TOKEN: "/settings/registration-tokens/:uid/edit",

  DEVELOPER_SETTINGS: "/settings/developer-settings",

  FILTERS: "/settings/filters",
};

export const PROJECT_OVERVIEW = {
  ROOT: "/projectoverview",
};

export const ORGANIZATION_OVERVIEW = {
  ROOT: "/overview",
};

export const WORKSPACES = {
  ROOT: "/workspaces/:tab(overview|usagecost)",
  CREATE_WORKSPACE: "/workspaces/create",
  WORKSPACE_DETAILS: "/workspace/:id",
  TAB_DETAILS: "/workspaces/:id/:tab(overview|backups)/:flag?",
  WORKSPACE_DETAILS_OVERVIEW:
    "/workspaces/:id/:tab(overview)/:flag(namespaces|pods|deployments|daemonsets|statefulsets|jobs|cronjobs|rolebindings|clusterrolebindings)",
  BACKUPS: `/workspaces/:id/:tab(backups)/:flag(backups|restores)`,
};

export const CLUSTER_GROUPS = {
  ROOT: "/clustergroups",
  CREATE_GROUP: "/clustergroups/create",
  DETAILS_OVERVIEW:
    "/clustergroups/:id/:tab(hostclusters|virtual-clusters|add-on-profiles|workloads|backups)/:flag?",
  CLUSTER_GROUP_BACKUPS:
    "/clustergroups/:id/:tab(backups)/:flag(backups|restores)",
  CLUSTER_GROUP_WORKLOADS_DETAILS:
    "/clustergroups/:id/:tab(workloads)/:flag(namespaces|pods|deployments|daemonsets|statefulsets|jobs|cronjobs|rolebindings|clusterrolebindings)",
  CREATE_HOST_CLUSTER: "/clustergroups/:id/host/new",
  CREATE_VIRTUAL_CLUSTER: "/clustergroups/:id/virtual/new",
  CLUSTER_GROUP_DETAILS: `/:clusterCategory(clustergroups)/:clusterGroupId/clusters/:id/:tab(overview|configure|nodes|workloads|events|backups|restores|scans|costs|appliances|vms)/:flag?`,
  SCAN_DETAILS: `/:clusterCategory(clustergroups)/:clusterGroupId/clusters/:id/:tab(scans)/:scanType/:scanUid`,
  CERTIFICATES: `/:clusterCategory(clustergroups)/:clusterGroupId/clusters/:id/certificates`,
};

export const VIRTUAL_CLUSTER = {
  ROOT: "/virtual-cluster",
  CREATE_CLUSTER: "/virtual-cluster/create",
};

export const APP_PROFILES = {
  ROOT: "/app-profiles",
  CREATE: "/app-profiles/create",
  DETAILS: "/app-profiles/:uid",
};

export const APP_MODE_OVERVIEW = {
  ROOT: "/appmodeoverview",
};

export const DEPLOYMENTS = {
  ROOT: "/deployments",
  CREATE: "/deployments/:appProfileId?/new",
  OVERVIEW: "/deployments/:uid/overview",
};

export const REGISTRIES = {
  ROOT: "/registries/:tab(helm|oci)",
  CREATE_REGISTRY: "/registries/:tab(helm|oci)/create",
  EDIT_REGISTRY: "/registries/:tab(helm|oci)/:uid/edit",
};
