import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Tooltip } from "antd";
import uniqBy from "lodash/uniqBy";

import ClusterImportedIcon from "assets/icons/cluster_imported.svg?component";
import ClusterTypeIcon from "assets/icons/cluster_type.svg?component";
import CircleErrorIcon from "assets/icons/circle_error.svg?component";
import CircleUpdateIcon from "assets/icons/circle_update.svg?component";
import VirtualClusterIcon from "assets/icons/nested_cluster.svg?component";

import Tags from "components/common/Tags";
import ProgressBar from "components/common/ProgressBar";
import Icon from "components/ui/Icon";
import Status from "components/styled/Status/new";
import HealthStatusMessage from "components/styled/HealthStatusMessage";

import { round } from "utils/number";
import { getEnvironments } from "utils/constants";
import * as colors from "utils/constants/colors";
import { formatTimestampToGeneralDateFormat } from "utils/date";
import { useSelector } from "react-redux";

const IconWrap = styled.div`
  position: relative;
  width: 24px;
  display: flex;
  align-items: center;
  margin: auto;

  img {
    color: transparent;
    max-height: 100%;
    object-fit: scale-down;
    max-width: 100%;
  }
`;

const ClusterNotification = styled.div`
  position: absolute;
  top: -8px;
  right: -3px;
  font-size: 16px;
  z-index: 1;
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
`;

const HealthIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClusterTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-left: 10px;
  }
`;

export function getClusterNodesType(machinePools) {
  if (machinePools.length === 0) {
    return "-";
  }

  const workerPools = machinePools
    .filter((pool) => !pool.labels?.includes("master"))
    .reduce(
      (acc, pool) => {
        return { size: acc.size + pool.size };
      },
      { size: 0 }
    );

  const masterPool = machinePools.find((pool) =>
    pool.labels?.includes("master")
  );

  return {
    masters: masterPool?.size,
    workers: workerPools?.size,
  };
}

export function getHealthyNodes(machinePools = []) {
  if (machinePools.length === 0) {
    return "-";
  }

  const totalMachines = machinePools.reduce(
    (acc, pool) => acc + (pool.size || 0),
    0
  );
  const healthyMachines = machinePools.reduce(
    (acc, pool) => acc + (pool.healthy || 0),
    0
  );

  return `${healthyMachines} / ${totalMachines}`;
}

export function useClusterColumns({
  context,
  isVirtualCluster = false,
  renderChevron = true,
  isDevMode = false,
  isClusterGroupCluster = false,
  isDashboardColumn = false,
} = {}) {
  const { t } = useTranslation();
  const ENVIRONMENTS = useSelector(getEnvironments);

  return [
    {
      title: t("Type"),
      key: "type",
      width: "64px",
      render(item) {
        const status = (item.status?.state || "").toLowerCase();
        const updateAvailable =
          ["running", "provisioning", "unknown"].includes(status) &&
          !!item?.status?.notifications?.isAvailable;
        const hasError = status === "error";
        const isImported = item.isBrownfield;
        const isVirtual = item?.isVirtual;

        const renderClusterTypeIcon = () => {
          if (isVirtual) {
            return <VirtualClusterIcon />;
          }

          if (isImported) {
            return (
              <Tooltip title={t("Imported Cluster")}>
                <ClusterImportedIcon />
              </Tooltip>
            );
          }
          return <ClusterTypeIcon />;
        };

        const renderNotificationIcon = () => {
          if (hasError) {
            return (
              <Tooltip title={t("Error Status")}>
                <ClusterNotification>
                  <CircleErrorIcon />
                </ClusterNotification>
              </Tooltip>
            );
          }
          if (updateAvailable) {
            return (
              <Tooltip title={t("Updates Available")}>
                <ClusterNotification>
                  <CircleUpdateIcon />
                </ClusterNotification>
              </Tooltip>
            );
          }
          return null;
        };

        return (
          <IconWrap>
            {renderNotificationIcon()}
            {renderClusterTypeIcon()}
          </IconWrap>
        );
      },
    },
    !isVirtualCluster && {
      title: t("Env"),
      key: "environment",
      sorter: true,
      align: "center",
      width: "70px",
      render(item) {
        let { cloudType } = item.specSummary.cloudConfig || {};
        const annotations = item.metadata?.annotations;
        const labels = item.metadata?.labels;
        if (annotations && annotations["spectrocloud.com/openshift"]) {
          cloudType = "openshift";
        }

        if (annotations && annotations.cloudType === "EKS-Anywhere") {
          cloudType = "eksa";
        }

        if (labels?.type === "eksa") {
          cloudType = "eksa";
        }

        //TODO: remove this once TENCENT cloud clusters are enabled
        if (cloudType === "tencent") {
          cloudType = "tke";
        }

        const env = ENVIRONMENTS.find(
          (environment) => environment.apiKey === cloudType
        );

        if (!env) {
          return "-";
        }

        return (
          <IconWrap>
            <img src={env.smallLogoPath} alt={env.name} />
          </IconWrap>
        );
      },
    },
    {
      title: t("Cluster Name"),
      key: "clusterName",
      sorter: isDashboardColumn ? false : true,
      render(item) {
        return <ClusterTitle>{item.metadata.name}</ClusterTitle>;
      },
    },
    isVirtualCluster &&
      isClusterGroupCluster && {
        title: t("Host Cluster"),
        key: "hostluster",
        dataIndex: ["status", "virtual", "hostCluster"],
        width: "178px",
        render(hostCluster) {
          return <span>{hostCluster?.name || "-"}</span>;
        },
      },
    isVirtualCluster &&
      !isDashboardColumn && {
        title: t("Cluster Group"),
        key: "clusterGroup",
        render(item) {
          const { clusterGroup } = item.status?.virtual || {};
          return <span>{clusterGroup?.name || "-"}</span>;
        },
      },
    {
      title: t("Project"),
      key: "project",
      dataIndex: ["specSummary", "projectMeta", "name"],
      render: (project) => <div>{project}</div>,
    },
    !isDashboardColumn && {
      title: t("Last Modified"),
      key: "lastModifiedTimestamp",
      sorter: true,
      render(data) {
        return (
          <span>
            {formatTimestampToGeneralDateFormat(
              data.metadata.lastModifiedTimestamp
            )}
          </span>
        );
      },
    },

    {
      title: t("Status"),
      key: "status",
      render(item) {
        const status = (item.status?.state || "").toLowerCase();
        const sandboxClusterLifecycleStatus = (
          item?.status?.virtual?.lifecycleStatus?.status || ""
        ).toLowerCase();

        const hasLifecycleStatus =
          isVirtualCluster &&
          status === "running" &&
          ["paused", "pausing", "resuming"].includes(
            sandboxClusterLifecycleStatus
          );

        if (!status) {
          return "-";
        }

        return (
          <Status
            status={hasLifecycleStatus ? sandboxClusterLifecycleStatus : status}
            loadingStatuses={[
              "provisioning",
              "deleting",
              "pausing",
              "resuming",
            ]}
          />
        );
      },
    },
    {
      title: t("Health"),
      key: "healthState",
      sorter: isDashboardColumn ? false : true,
      align: "center",
      width: "98px",
      dataIndex: ["status"],
      render({ health, state }) {
        const healthConditions = uniqBy(health?.conditions || [], "message");
        const renderMessagesInTooltip = (condition, index) => (
          <div key={index}>{condition.message}</div>
        );

        return (
          <HealthIconWrap>
            <HealthStatusMessage
              state={state === "Running" ? health?.state?.toLowerCase() : null}
              message={healthConditions.map(renderMessagesInTooltip)}
              compact
              health={health}
            />
          </HealthIconWrap>
        );
      },
    },
    !isVirtualCluster && {
      title: t("Healthy Nodes"),
      key: "nodes",
      render(data) {
        const machinePools = data.specSummary?.cloudConfig?.machinePools || [];
        const poolNodes = getClusterNodesType(machinePools);

        return (
          <Tooltip
            placement={"right"}
            title={
              <>
                <div>{`${t("Master Nodes")}: ${
                  poolNodes.masters || "n/a"
                }`}</div>
                <div>{`${t("Worker Nodes")}: ${
                  poolNodes.workers || "n/a"
                }`}</div>
              </>
            }
          >
            {getHealthyNodes(machinePools)}
          </Tooltip>
        );
      },
    },
    !isVirtualCluster && {
      title: t("CPU"),
      key: "cpuUsage",
      sorter: true,
      dataIndex: ["status", "metrics", "cpu"],
      width: "178px",
      render(cpu) {
        if (
          typeof cpu?.usage === "undefined" ||
          typeof cpu?.total === "undefined"
        ) {
          return <div>-</div>;
        }
        const { usage, total } = cpu || {};
        const percent = (usage * 100) / total;
        const label = `${round(usage / 1000, 2)} CPUs / ${round(
          total / 1000,
          2
        )} CPUs`;

        return (
          <>
            <ProgressBar
              percent={percent || 0}
              color={percent > 50 ? colors.orange : colors.green}
              label={label}
            />
          </>
        );
      },
    },
    !isVirtualCluster && {
      title: t("Memory"),
      key: "memoryUsage",
      sorter: true,
      dataIndex: ["status", "metrics", "memory"],
      width: "178px",
      render(memory) {
        if (
          typeof memory?.usage === "undefined" ||
          typeof memory?.total === "undefined"
        ) {
          return <div>-</div>;
        }
        const { usage, total } = memory || {};
        const percent = (usage * 100) / total;
        const formattedNumerator = round(usage / 976562, 2);
        const formattedDenominator = round(total / 976562, 2);

        const label = `${formattedNumerator} Gb / ${formattedDenominator} Gb`;

        return (
          <ProgressBar
            percent={percent || 0}
            color={percent > 50 ? colors.orange : colors.green}
            label={label}
          />
        );
      },
    },
    !isDashboardColumn && {
      title: t("Tags"),
      key: "tags",
      dataIndex: ["metadata", "labels"],
      render(labels = {}) {
        return <Tags tags={labels} />;
      },
    },
    renderChevron &&
      !isDashboardColumn && {
        render: function Chevron() {
          return <Icon type="right" style={{ color: colors.midGray }} />;
        },
      },
  ].filter(Boolean);
}
