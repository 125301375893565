import api, { nonProjectApi } from "services/api";
import {
  ClusterGroupSchema,
  ClusterProfileSchema,
  ClusterProfileTemplateSchema,
  ClusterSchema,
} from "utils/schemas";
import {
  getClusterGroup,
  getClusterGroupEntity,
  getClusterGroupProfileParams,
  getClusterGroupProfileTemplate,
} from "../selectors/details";

import { WorkloadNamespaceSchema } from "utils/schemas";

import moment from "moment";
import store from "services/store";
import { GENERAL_DATE_FORMAT } from "utils/constants";
import { namespacesFetcher } from "../services/workloads/namespaces";
import dataFetcher from "modules/dataFetcher";
import createFormActions from "modules/form/actions";
import {
  getDefaultPresetsFromValues,
  getPackValuesWithoutPresetsComment,
} from "utils/parsers";
import { profileModule } from "../services/create";
import notifications from "services/notifications";
import axios from "axios";
import i18next from "i18next";

export function fetchClusterGroup(uid, isClusterGroupProjectScoped = true) {
  return (dispatch) => {
    const promise = isClusterGroupProjectScoped
      ? api.get(`v1/clustergroups/${uid}`)
      : nonProjectApi.get(`v1/clustergroups/${uid}`);

    dispatch({
      type: "FETCH_CLUSTER_GROUP",
      promise,
      schema: ClusterGroupSchema,
    });

    return promise;
  };
}

export function refreshClusterGroup() {
  return function thunk(dispatch, getState) {
    const clusterGroup = getClusterGroupEntity(getState());
    const promise = api.get(`v1/clustergroups/${clusterGroup?.metadata?.uid}`);
    dispatch({
      promise,
      type: "REFRESH_CLUSTER_GROUP",
      schema: ClusterGroupSchema,
    });
    return promise;
  };
}

export function getClusterGroupByUid(uid) {
  return (dispatch, getState) => {
    const clusterGroup = getClusterGroupEntity(getState());

    if (clusterGroup?.metadata?.uid === uid) {
      return;
    }
    return dispatch(fetchClusterGroup(uid));
  };
}

export function fetchNamespacesConsumption() {
  return async function thunk(dispatch, getState) {
    const uid = getState().location.params?.id;
    const promise = async function loadData() {
      const startTime = moment()
        .subtract(1, "hour")
        .utc()
        .format(GENERAL_DATE_FORMAT);
      const endTime = moment().utc().format(GENERAL_DATE_FORMAT);

      const filters = {
        filter: {
          workspaces: [uid],
          endTime,
          startTime,
          includeMasterMachines: true,
        },
        options: {
          groupBy: "namespace",
          period: 60,
        },
      };
      const rawData = await api.post(
        "v1/dashboard/spectroclusters/resources/consumption",
        filters
      );

      const { result: namespaces } = namespacesFetcher.selector(getState());

      return rawData.resources.map((resource) => {
        const namespace = (namespaces?.items || []).find((ns) => {
          return ns.metadata.entity.name === resource.entity.name;
        });

        return {
          metadata: {
            entity: {
              ...resource.entity,
              uid: namespace?.metadata.entity.uid,
            },
          },
          status: {
            usage: {
              total: resource.total,
            },
          },
        };
      });
    };

    dispatch({
      type: "CLUSTER_WORKSPACE_WORKLOAD_NAMESPACES_CONSUMPTION",
      promise: promise(),
      schema: [WorkloadNamespaceSchema],
    });
  };
}

export const fetchAllDashboardClusters = dataFetcher({
  selectors: ["dashboardClusters"],
  async fetchData() {
    const response = await api.post("v1/dashboard/spectroclusters");
    return {
      items: response?.items,
    };
  },
});

export function fetchResolvedValues(uid, isClusterGroupProjectScoped = true) {
  return function (dispatch) {
    const resolvedValues = isClusterGroupProjectScoped
      ? api.get(`v1/clustergroups/${uid}/packs/resolvedValues`)
      : nonProjectApi.get(`v1/clustergroups/${uid}/packs/resolvedValues`);

    const promise = resolvedValues
      .then((res) => res.profiles)
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        notifications.error({
          message: i18next.t(
            "An error occured while trying to get the resolved values."
          ),
          description: error?.message,
        });
      });

    dispatch({
      type: "FETCH_RESOLVED_VALUES",
      promise,
      schema: ClusterSchema,
    });

    return promise;
  };
}

export function onClusterGroupProfileConfigurationSave(data) {
  return async function thunk(dispatch, getState) {
    const clusterGroupUid = getState().location.params.id;

    const promise = api.put(`v1/clustergroups/${clusterGroupUid}/profiles`, {
      profiles: profileModule.payload,
      spcApplySettings: {
        actionType: data.timeToTrigger,
      },
    });

    dispatch({
      type: "CONFIGURE_CLUSTER_GROUP_PROFILES",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18next.t(
          "An error occured while trying to update the profiles."
        ),
        description: error?.message,
      });
      return;
    }
    notifications.withAudit({
      message: i18next.t("Cluster profiles were updated successfully"),
      promise,
    });

    dispatch(fetchClusterGroupProfiles({ withConfiguration: true }));

    return promise;
  };
}

export const addOnProfileFormActions = createFormActions({
  init() {
    const params = getClusterGroupProfileParams(store.getState()).reduce(
      (packAccumulator, pack) => {
        return {
          ...packAccumulator,
          [pack.guid]: {
            values: getPackValuesWithoutPresetsComment(pack.values),
            presets: getDefaultPresetsFromValues(pack.values),
          },
        };
      },
      {}
    );

    return Promise.resolve(params);
  },
  submit: (data) =>
    store.dispatch(onClusterGroupProfileConfigurationSave(data)),
});

export function fetchClusterGroupProfiles({ withConfiguration = false } = {}) {
  return async function (dispatch, getState) {
    dispatch({ type: "FETCH_CLUSTER_PROFILE_INITIALIZE" });

    let clusterGroup = getClusterGroup(getState());
    const getClusterGroupPromise = api
      .get(`v1/clustergroups/${clusterGroup.metadata.uid}`)
      .then((data) => ({
        metadata: {
          uid: data.metadata.uid,
        },
        spec: {
          clusterProfileRef: data.spec.clusterProfileRef,
          clusterProfileTemplates: data.spec.clusterProfileTemplates,
        },
      }));
    dispatch({
      promise: getClusterGroupPromise,
      type: "REFRESH_CLUSTER_GROUP_PACKS_SUCCESS",
      schema: ClusterGroupSchema,
    });

    await getClusterGroupPromise;
    clusterGroup = getClusterGroup(getState());

    if (withConfiguration) {
      const resolvedValues = await dispatch(
        fetchResolvedValues(clusterGroup.metadata.uid)
      );
      profileModule.actions.initialize({ profiles: [] });
      profileModule.actions.updateResolvedValues(resolvedValues);
    }

    const profilesPromise = api.get(
      `v1/clustergroups/${clusterGroup.metadata.uid}/profiles?includePackMeta=schema,presets`
    );

    dispatch({
      promise: profilesPromise,
      type: "FETCH_CLUSTER_GROUP_BUNDLE",
      schema: { profiles: [ClusterProfileTemplateSchema] },
    });

    await profilesPromise;

    let profiles = getClusterGroupProfileTemplate(getState());
    function fetchProfile(uid) {
      const promise = api.get(`v1/clusterprofiles/${uid}`);
      store.dispatch({
        type: "PROFILE_STACK.FETCH_PROFILE",
        promise,
        schema: ClusterProfileSchema,
      });
      return promise;
    }

    if (withConfiguration) {
      await Promise.allSettled(
        profiles.map(async (profile) => {
          return fetchProfile(profile.metadata.uid);
        })
      );
      profiles = getClusterGroupProfileTemplate(getState());
    }

    const clusterGroupLabels = clusterGroup?.metadata?.labels;
    const cloudType = clusterGroup.spec.cloudType;
    const ignoreImport =
      clusterGroupLabels?.imported === "false" || cloudType === "edge-native";

    profileModule.actions.initialize({
      profiles,
      options: {
        cloudType: clusterGroup.spec.cloudType,
        editMode: true,
        allowInfraProfileRemoval: false,
        allowSystemProfiles: ignoreImport
          ? false
          : clusterGroup.metadata.annotations.primaryEdgeHostUid,
      },
    });

    dispatch({ type: "FETCH_CLUSTER_GROUP_PROFILE_INITIALIZE_SUCCESS" });

    const redirectSelectedPack = getState().location.params.layerUid;
    if (redirectSelectedPack) {
      profiles.forEach((profile) => {
        const foundPack = profile.spec?.published?.packs.find(
          (pack) => pack?.metadata.uid === redirectSelectedPack
        );

        if (!foundPack) {
          return;
        }

        if (foundPack.spec.type === "manifest") {
          profileModule.actions.onManifestSelect({
            manifestGuid: foundPack.manifests?.[0].guid,
            layerGuid: foundPack.guid,
            profileGuid: profile.guid,
            editMode: true,
            isAttachedManifest: false,
          });
          return;
        }

        profileModule.actions.selectLayer(foundPack.guid);

        return;
      });
    }
  };
}
