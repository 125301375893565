export const initialState = {
  isLoading: true,
  currentClusterGroupGuid: null,
  currentPackGuid: null,
  profiles: [],
  isClusterGroupProfileLoading: true,
  arePacksValidating: false,
  packsValidationErrors: [],
  resolvedValues: [],
};

export function FETCH_CLUSTER_GROUP_REQUEST(nextState) {
  nextState.isLoading = true;
  nextState.isClusterGroupProfileLoading = true;
}

export function FETCH_CLUSTER_GROUP_FAILURE(nextState) {
  nextState.isLoading = false;
  nextState.currentClusterGroupGuid = null;
}

export function FETCH_CLUSTER_GROUP_NORMALIZATION(nextState, { result }) {
  nextState.currentClusterGroupGuid = result;
  nextState.isLoading = false;
}

export function REFRESH_CLUSTER_GROUP_NORMALIZATION(nextState, { result }) {
  nextState.currentClusterGroupGuid = result;
}

export function REFRESH_CLUSTER_GROUP_PACKS_SUCCESS(nextState, { result }) {
  nextState.currentPackGuid = result.spec.clusterProfileTemplates.find(
    (template) => template.uid === result.spec?.clusterProfileRef?.uid
  )?.packs?.[0]?.guid;
}

export function FETCH_CLUSTER_GROUP_BUNDLE_NORMALIZATION(
  nextState,
  { result }
) {
  nextState.profiles = result.profiles;
}

export function FETCH_CLUSTER_GROUP_BUNDLE_FAILURE(nextState) {
  nextState.isClusterGroupProfileLoading = false;
}

export function FETCH_CLUSTER_GROUP_PROFILE_INITIALIZE_SUCCESS(nextState) {
  nextState.isClusterGroupProfileLoading = false;
}

export function VALIDATE_EDIT_CLUSTER_GROUP_PROFILE_PACKS_REQUEST(nextState) {
  nextState.arePacksValidating = true;
}

export function VALIDATE_EDIT_CLUSTER_GROUP_PROFILE_PACKS_FAILURE(nextState) {
  nextState.arePacksValidating = false;
}

export function VALIDATE_EDIT_CLUSTER_GROUP_PROFILE_PACKS_SUCCESS(
  nextState,
  { result }
) {
  nextState.packsValidationErrors = result || [];
  nextState.arePacksValidating = false;
}

export function CONFIGURE_CLUSTER_GROUP_PROFILES_REQUEST(nextState) {
  nextState.isSubmittingProfile = true;
}

export function CONFIGURE_CLUSTER_GROUP_PROFILES_SUCCESS(nextState) {
  nextState.isClusterGroupProfileLoading = true;
  nextState.isSubmittingProfile = false;
}

export function CONFIGURE_CLUSTER_GROUP_PROFILES_FAILURE(nextState) {
  nextState.isSubmittingProfile = false;
}
