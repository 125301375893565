import i18n from "i18next";
import {
  clusterProfileFormActions,
  CLUSTER_PROFILE_MODULE,
} from "./layerConfig";
import { getCurrentStep } from "state/clusterprofile/selectors/create";

import api from "services/api";
import notifications from "services/notifications";
import historyService from "services/history";
import ModalService from "services/modal";
import createFormActions from "modules/form/actions";

import store, { getStoreEntity } from "services/store";
import {
  cloneClusterProfileModal,
  deleteImportClusterProfileFile,
  importClusterProfileModal,
  importClusterProfileValidateModal,
  profileBuilderCreateModule,
  profileListActions,
} from "../services";
import { ClusterProfileSchema } from "utils/schemas";
import Validator from "services/validator";
import { Missing, SemanticVersion, ApplyIf } from "services/validator/rules";
import { getLayerConfigurationStatus } from "../selectors/layerConfig";
import { getCurrentContext } from "state/auth/selectors/common";
import { cloneDeep } from "lodash";

export const onCancelClusterProfileModal = new ModalService("cancelModal");
export const CLONE_PROFILE_FORM_MODULE = "cloneClusterProfile";
export const IMPORT_CLUSTER_PROFILE_MODULE = "importClusterProfile";
export const VALIDATE_IMPORT_CLUSTER_PROFILE_MODULE =
  "validateImportClusterProfile";

const fieldsToValidate = {
  "basic-info": ["name", "version", "tags"],
  "cloud-type": ["cloudType"],
};

function setStepDescription() {
  return (dispatch, getState) => {
    const { stepId } = getState().clusterprofile.create;
    const formData = getState().forms.clusterprofile.data;
    let description = "";
    if (stepId === "basic-info") {
      description = formData.name;
    }
    if (stepId === "cloud-type") {
      description = formData.cloudType;
    }
    if (stepId === "profile-layers") {
      description = "Complete";
    }

    dispatch({
      type: "CLUSTER_PROFILE_SET_DESCRIPTION",
      description,
      stepId,
    });
  };
}

function validateStep() {
  return function thunk(dispatch, getState) {
    const stepId = getState().clusterprofile?.create?.stepId;
    const fields = fieldsToValidate[stepId];

    if (!fields) {
      return [];
    }

    return dispatch(
      clusterProfileFormActions.validateField({
        name: fields,
        module: CLUSTER_PROFILE_MODULE,
      })
    );
  };
}

export function nextStep() {
  return async (dispatch, getState) => {
    let stepId = getState().clusterprofile.create.stepId;
    const formData = getState().forms.clusterprofile.data;
    if (stepId === "profile-layers") {
      try {
        await profileBuilderCreateModule.actions.validateProfilePacks(formData);
      } catch (err) {
        return;
      }
    }
    const errors = await dispatch(validateStep());
    const isIncomplete =
      getLayerConfigurationStatus(getState()) === "incomplete";

    if (isIncomplete || errors.length > 0) {
      return;
    }

    dispatch(setStepDescription());
    dispatch({ type: "CLUSTER_PROFILE_NEXT_STEP" });
    stepId = getState().clusterprofile.create.stepId;
    if (stepId === "profile-layers") {
      profileBuilderCreateModule.actions.startConfiguration();
    }
  };
}

export function prevStep() {
  return (dispatch) => {
    dispatch(setStepDescription());
    dispatch({ type: "CLUSTER_PROFILE_PREV_STEP" });
    dispatch(
      clusterProfileFormActions.clearErrors({ module: CLUSTER_PROFILE_MODULE })
    );
  };
}

export function changeStep(step) {
  return (dispatch, getState) => {
    if (step <= getCurrentStep(getState())) {
      dispatch({
        type: "CLUSTER_PROFILE_CHANGE_STEP",
        stepNumber: step,
      });
    }
  };
}

export function submitClusterProfile() {
  return async function thunk(dispatch) {
    dispatch(
      clusterProfileFormActions.submit({ module: CLUSTER_PROFILE_MODULE })
    );
  };
}

export function onModalClose() {
  const { tab = "cluster" } = store.getState().location.params;

  const formData = store.getState().forms.clusterprofile.data;

  if (formData.name === "") {
    historyService.push(`/profiles/${tab}`);
    return;
  }

  onCancelClusterProfileModal.open().then(async () => {
    historyService.push(`/profiles/${tab}`);
  });
}

const cloneProfileValidator = new Validator();
cloneProfileValidator.addRule(
  ["name", "version", "sourceProfileVersion"],
  Missing()
);
cloneProfileValidator.addRule("version", SemanticVersion());
cloneProfileValidator.addRule(
  "projectUid",
  ApplyIf((_1, _2, data) => data.scope === "project", Missing())
);

export async function validateClusterProfileInCurrentScope({
  guid,
  name,
  version,
  scope,
  projectUid,
} = {}) {
  const clusterProfile = getStoreEntity(guid, ClusterProfileSchema);

  const promise = api.post(
    `v1/clusterprofiles/${clusterProfile.metadata.uid}/clone/validate`,
    {
      name,
      version,
      target: {
        scope,
        projectUid,
      },
    }
  );

  try {
    await promise;
  } catch (error) {
    return error;
  }
}

export async function cloneProfile({
  guid,
  name,
  version = "1.0.0",
  sourceProfileVersion,
  scope = "project",
  projectUid,
}) {
  const clusterProfile = getStoreEntity(guid, ClusterProfileSchema);
  const selectedProfileVersion = clusterProfile?.spec?.versions.find(
    (profile) => profile.version === sourceProfileVersion
  );

  const promise = api.post(
    `v1/clusterprofiles/${selectedProfileVersion?.uid}/clone`,
    {
      metadata: {
        name,
        version,
        target: {
          scope,
          projectUid,
        },
      },
    }
  );

  return promise;
}

export const cloneClusterProfileFormActions = createFormActions({
  validator: cloneProfileValidator,
  init: () => {
    const clusterProfile = getStoreEntity(
      cloneClusterProfileModal.data?.profileGuid,
      ClusterProfileSchema
    );
    const currentContext = getCurrentContext(store.getState());

    return Promise.resolve({
      name: `${clusterProfile.metadata.name}-copy` || "",
      version: "1.0.0",
      scope: currentContext?.isAdmin ? "tenant" : "project",
      projectUid: currentContext?.projectUid,
      sourceProfileVersion: "",
      versions: clusterProfile?.spec?.versions,
    });
  },
  submit: async (data) => {
    const profileGuid = cloneClusterProfileModal.data?.profileGuid;

    const error = await validateClusterProfileInCurrentScope({
      guid: profileGuid,
      ...data,
    });

    if (error) {
      let message = error.message;

      if (error.code === "ClusterProfileAlreadyExists") {
        message = i18n.t(
          `A cluster profile with the name "{{ value }}" and version "{{ version }}" already exists in this scope`,
          {
            value: data.name,
            version: data.version,
          }
        );
      }
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: message,
      });
      return Promise.reject();
    }

    const promise = cloneProfile({
      guid: profileGuid,
      name: data.name,
      version: data.version,
      sourceProfileVersion: data.sourceProfileVersion,
      scope: data.scope,
      projectUid: data.projectUid,
    });

    try {
      await promise;

      notifications.success({
        message: i18n.t(
          "Cluster profile '{{profileName}}' was created successfully",
          { profileName: data.name }
        ),
      });

      store.dispatch(profileListActions.initialize("clusterprofiles"));

      return promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong while trying to clone the cluster profile"
        ),
        description: error?.message,
      });
    }
  },
});

export function openCloneClusterProfileModal(guid) {
  return (dispatch) => {
    cloneClusterProfileModal.open({ profileGuid: guid }).then(async () => {
      return dispatch(
        cloneClusterProfileFormActions.submit({
          module: CLONE_PROFILE_FORM_MODULE,
        })
      );
    });
  };
}

export function onScopeFieldChange(value) {
  return (dispatch) => {
    dispatch(
      cloneClusterProfileFormActions.onChange({
        name: "scope",
        value,
        module: CLONE_PROFILE_FORM_MODULE,
      })
    );

    if (value === "tenant") {
      dispatch(
        cloneClusterProfileFormActions.onChange({
          name: "projectUid",
          value: null,
          module: CLONE_PROFILE_FORM_MODULE,
        })
      );
    }
  };
}

export function openImportClusterProfileModal() {
  return (dispatch) => {
    importClusterProfileModal.open().then(async () => {
      await dispatch(
        importClusterProfileFormActions.submit({
          module: IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );
    });
  };
}

export function validateProfileData() {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        validateImportClusterProfileForm.init({
          module: VALIDATE_IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );
    } catch (err) {
      return;
    }

    const validatePayload =
      getState()?.forms?.[VALIDATE_IMPORT_CLUSTER_PROFILE_MODULE]?.data
        ?.validatePayload;

    if (
      (validatePayload?.spec?.template?.packs || []).every(
        (pack) => (pack?.registry?.matchingRegistries || []).length === 0
      )
    ) {
      dispatch(
        validateImportClusterProfileForm.submit({
          module: VALIDATE_IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );
      return;
    }

    importClusterProfileValidateModal.open().then(async () => {
      const errors = await dispatch(
        validateImportClusterProfileForm.validateForm({
          module: VALIDATE_IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );

      if (errors.length > 0) {
        throw new Error();
      }

      dispatch(
        validateImportClusterProfileForm.submit({
          module: VALIDATE_IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );
    });
  };
}

export function onClusterProfileFileUpload(file) {
  return (dispatch) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      let parsedResponse;

      try {
        parsedResponse = JSON.parse(reader.result);
      } catch (e) {
        notifications.error({
          message: i18n.t("The content of the file is not a valid JSON object"),
        });
        return;
      }

      dispatch(
        importClusterProfileFormActions.batchChange({
          updates: {
            importFile: [file],
            profileData: JSON.stringify(parsedResponse, null, 2),
          },
          module: IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );
    };
    reader.onerror = () =>
      notifications.error({
        message: i18n.t("Failed reading file"),
      });
  };
}

export function onClusterProfileFileRemove() {
  return (dispatch) => {
    deleteImportClusterProfileFile.open().then(() =>
      dispatch(
        importClusterProfileFormActions.batchChange({
          updates: {
            profileData: "",
            importFile: [],
            validated: false,
          },
          module: IMPORT_CLUSTER_PROFILE_MODULE,
        })
      )
    );
  };
}

export function onClusterProfileEditorChange(value) {
  return (dispatch) => {
    let jsonObj;

    try {
      jsonObj = JSON.parse(value);
    } catch (err) {
      dispatch(
        importClusterProfileFormActions.onChange({
          name: "profileData",
          value: value,
          module: IMPORT_CLUSTER_PROFILE_MODULE,
        })
      );
      return;
    }

    dispatch(
      importClusterProfileFormActions.onChange({
        name: "profileData",
        value: JSON.stringify(jsonObj, null, 2),
        module: IMPORT_CLUSTER_PROFILE_MODULE,
      })
    );
  };
}

export const importClusterProfileFormActions = createFormActions({
  init: () => {
    return Promise.resolve({
      importFile: [],
      profileData: "",
      validated: false,
    });
  },
  submit: async (data) => {
    try {
      await api.post("v1/clusterprofiles/import?publish=true", {
        ...JSON.parse(data.profileData),
      });

      store.dispatch(profileListActions.fetchItems("clusterprofiles"));
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err?.message,
      });

      throw new Error(err);
    }
  },
});

const validateImportClusterProfileValidator = new Validator();
validateImportClusterProfileValidator.addRule(function* (data) {
  const { validatePayload, ...packRegistries } = data;

  for (const packName in packRegistries) {
    yield {
      field: packName,
      result: !packRegistries[packName] ? i18n.t("Missing field") : false,
    };
  }
});

export const validateImportClusterProfileForm = createFormActions({
  validator: validateImportClusterProfileValidator,
  init: async () => {
    const data = store.getState().forms.importClusterProfile.data;
    let validatePayload;

    try {
      validatePayload = await api.post("v1/clusterprofiles/import/validate", {
        ...JSON.parse(data.profileData),
      });
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err?.message,
      });
      throw new Error(err);
    }

    return Promise.resolve({
      validatePayload,
      ...(validatePayload?.spec?.template?.packs || []).reduce((acc, pack) => {
        // manifest layers don't have a registry, thus removing them from the modal validation
        if (pack.type === "manifest") {
          return acc;
        }

        return {
          ...acc,
          [pack.name]: pack?.registry?.metadata?.uid || "",
        };
      }, {}),
    });
  },
  submit: async (data) => {
    const { validatePayload, ...packRegistries } = data;
    const updatedPayload = cloneDeep(validatePayload);

    (updatedPayload?.spec?.template?.packs || []).forEach((pack) => {
      const allRegistries = [
        ...(pack?.registry?.matchingRegistries || []),
        pack?.registry?.metadata?.uid ? pack.registry.metadata : null,
      ].filter(Boolean);

      const selectedRegistry = allRegistries.find(
        (registry) => registry.uid === packRegistries[pack.name]
      );

      pack.registry.metadata = selectedRegistry || { uid: "", name: "" };
      delete pack.registry.matchingRegistries;
    });

    store.dispatch(
      importClusterProfileFormActions.batchChange({
        updates: {
          validated: true,
          profileData: JSON.stringify(updatedPayload, null, 2),
        },
        module: IMPORT_CLUSTER_PROFILE_MODULE,
      })
    );
  },
});
