import React from "react";
import { createConnector } from "modules/binder";
import { createSelector } from "reselect";
import styled from "styled-components";
import store from "services/store";

import ContainerIcon from "assets/icons/container.svg?component";
import HelmChartIcon from "assets/icons/helm_chart_layer.svg?component";
import ManifestIcon from "assets/icons/manifest.svg?component";
import MongoDBIcon from "assets/icons/mongodb.svg?component";
import MySQLIcon from "assets/icons/mysql.svg?component";
import PostgresIcon from "assets/icons/postgres.svg?component";
import RedisIcon from "assets/icons/redis.svg?component";
import CockroachIcon from "assets/icons/cockroach.svg?component";
import YugabyteIcon from "assets/icons/yugabyte.svg?component";
import MinioIcon from "assets/icons/minio.svg?component";
import DatabaseIcon from "assets/icons/database.svg?component";
import NatsIcon from "assets/icons/nats.svg?component";
import VaultIcon from "assets/icons/vault.svg?component";
import KafkaIcon from "assets/icons/kafka.svg?component";
import AmazonS3Icon from "assets/icons/amazon_s3.svg?component";

import Ellipsis from "components/common/Ellipsis";
import Icon from "components/ui/Icon";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { warning } from "utils/constants/colors";
import { v4 as uuid } from "uuid";
import { appProfileDetailFetcher } from "state/appprofiles/services/details";
import api from "services/api";
import _ from "lodash";
import { updateInstallOrder } from "utils/yaml";

export const SERVICE_ICONS = {
  container: ContainerIcon,
  helm: HelmChartIcon,
  manifest: ManifestIcon,
  "child-manifest": ManifestIcon,
  "operator-instance": DatabaseIcon,
  "mongodb-community-operator": MongoDBIcon,
  "mysql-operator": MySQLIcon,
  "postgresql-operator": PostgresIcon,
  "redis-operator": RedisIcon,
  "minio-operator": MinioIcon,
  "cockroachdb-operator": CockroachIcon,
  "yugabyte-operator": YugabyteIcon,
  "nats-operator": NatsIcon,
  "kafka-operator": KafkaIcon,
  "amazon-operator": AmazonS3Icon,
  "vault-operator": VaultIcon,
};

const TreeNodeContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FileName = styled.div`
  max-width: 80%;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    color: ${warning};
  }
`;

const IconWrapper = styled.div`
  width: 26px;
  display: flex;

  svg {
    color: #27ac9b;
  }
`;

function TreeNodeContent({ fileData, errors = [] }) {
  const TierIcon =
    SERVICE_ICONS[fileData?.subType] || SERVICE_ICONS[fileData.type];

  return (
    <TreeNodeContentWrapper>
      <FileName>
        <Ellipsis>{fileData.name}</Ellipsis>
        {errors.length > 0 && <Icon awesome={faInfoCircle} />}
      </FileName>
      <IconWrapper>{TierIcon ? <TierIcon /> : null}</IconWrapper>
    </TreeNodeContentWrapper>
  );
}

export function updateFilesInstallOrders({ module, files = [] }) {
  const { drafts } = module.state;

  files.forEach((file, index) => {
    module.dispatch({
      type: module.actions.drafts.stashForm,
      formData: {
        ...drafts[file],
        values: updateInstallOrder({
          values: drafts[file].values,
          installOrder: files.length - index,
        }),
      },
    });
  });
}

function syncFilesFromDrafts({ module, dropIndex }) {
  const { files, childrenMap } = module.state.fileList;
  const drafts = module.state.drafts;

  if (!drafts) {
    return;
  }

  files.forEach((fileGuid) => {
    const draftData = drafts[fileGuid];
    const children = draftData.manifests;
    const existingChildrenGuids = childrenMap[fileGuid];
    const childrenGuids = children.map((manifest) => manifest.guid);

    const filesToAdd = _.difference(childrenGuids, existingChildrenGuids);
    const filesToDelete = _.difference(existingChildrenGuids, childrenGuids);

    filesToAdd.forEach((childGuid) => {
      const child = children.find((manifest) => manifest.guid === childGuid);

      module.dispatch({
        type: module.actions.fileList.addChild,
        file: child,
        index: dropIndex,
        parentGuid: fileGuid,
      });
    });

    filesToDelete.forEach((childGuid) => {
      module.dispatch({
        type: module.actions.fileList.removeFile,
        fileGuid: childGuid,
      });
    });
  });
}

function onFileDrop({ dragKey, dropKey, module }) {
  const {
    fileList: { childrenMap, files, deletedChildren },
    drafts,
  } = module.state;

  function extractMetaFromKey(key) {
    return {
      type: files.includes(key) ? "parent" : "child",
      guid: key,
      parentGuid: Object.keys(childrenMap).find((guid) =>
        childrenMap[guid].includes(key)
      ),
    };
  }
  const source = extractMetaFromKey(dragKey);
  const target = extractMetaFromKey(dropKey);

  if (
    source.parentGuid === (target.parentGuid || target.guid) ||
    (source.type === "child" &&
      ["operator-instance", "container"].includes(drafts[target.guid].type))
  ) {
    return null;
  }

  const { name, kind, type, values, uid } = drafts[source.guid];
  const guid = uuid();

  if (source.type === "child") {
    module.dispatch({
      type: module.actions.drafts.stashForm,
      formData: {
        ...drafts[source.parentGuid],
        manifests: drafts[source.parentGuid].manifests.filter(
          ({ guid }) => guid !== source.guid
        ),
      },
    });

    const originalDeletedFile = deletedChildren.find(
      (pair) => pair.guid === drafts?.[source.guid]?.originalGuid
    );

    // create new File
    let newFile = !!originalDeletedFile
      ? { ...drafts[originalDeletedFile.guid] }
      : {
          kind,
          name,
          type,
          values,
          guid,
          originalGuid: drafts[source.guid].originalGuid || source.guid,
        };

    if (!values && uid && drafts[source.parentGuid]?.uid) {
      const profile = appProfileDetailFetcher.selector(store.getState()).result;

      function saveContentFor(manifest, overwrites) {
        const values = overwrites.spec?.published?.content;
        module.dispatch({
          type: module.actions.fileList.updateEntity,
          fileGuid: manifest.guid,
          overwrites: {
            values,
          },
        });

        module.dispatch({
          type: module.actions.drafts.stashForm,
          formData: {
            ...manifest,
            values,
          },
        });
      }

      api
        .get(
          `v1/appProfiles/${profile.metadata.uid}/tiers/${
            drafts[source.parentGuid].uid
          }/manifests/${uid}`
        )
        .then((manifestData) => {
          saveContentFor(newFile, manifestData);
          saveContentFor(drafts[source.guid], manifestData);
        });
    }

    module.dispatch({
      type: module.actions.drafts.stashForm,
      formData: newFile,
    });

    const targetEntity =
      drafts[target.type === "child" ? target.parentGuid : target.guid];

    const targetManifests = [...targetEntity.manifests];

    let dropIndex = 0;
    if (target.type === "child") {
      dropIndex =
        targetManifests.findIndex((manifest) => manifest.guid === target.guid) +
        1;
    }

    targetManifests.splice(dropIndex, 0, newFile);
    module.dispatch({
      type: module.actions.drafts.stashForm,
      formData: {
        ...targetEntity,
        manifests: targetManifests,
      },
    });

    if (
      [source.parentGuid, target.parentGuid || target.guid].includes(
        module.state.fileList.activeFile
      )
    ) {
      store.dispatch(module.form.actions.init({ ...module.form.state.data }));
    }

    syncFilesFromDrafts({ module, dropIndex });
  }

  if (source.type === "parent") {
    const updates = [...files];
    const targetParentGuid =
      target.type === "parent" ? target.guid : target.parentGuid;

    const sourceFileIndex = files.indexOf(source.guid);
    const targetFileIndex = files.indexOf(targetParentGuid);

    updates.splice(sourceFileIndex, 1);
    updates.splice(targetFileIndex, 0, source.guid);

    module.dispatch({
      type: module.actions.fileList.reorderFiles,
      reorderedFiles: updates,
    });

    if (module.configuration.updateInstallOrders) {
      updateFilesInstallOrders({ module, files: updates });
    }
  }
}

const fileListConnector = createConnector({
  selectors: {
    filesTreeData: createSelector(
      (state) => state.fileList?.files || [],
      (state) => state.fileList?.entities || {},
      (state) => state.fileList?.childrenMap || {},
      (state) => state?.validations || {},
      (files, entities, childrenMap, validations) => {
        function appendChildren(fileGuid) {
          if (childrenMap[fileGuid]) {
            return childrenMap[fileGuid].map((childGuid) => {
              const childFileData = {
                title: (
                  <TreeNodeContent
                    fileData={entities[childGuid]}
                    errors={validations[childGuid]}
                  />
                ),
                key: childGuid,
                isLeaf: true,
              };

              if (childrenMap[childGuid]) {
                return {
                  ...childFileData,
                  children: appendChildren(childGuid),
                  isLeaf: false,
                };
              }

              return childFileData;
            });
          }
        }

        const treeData = files
          .map((fileGuid) => {
            const fileData = entities[fileGuid];
            const errors = validations[fileGuid];

            return {
              title: <TreeNodeContent fileData={fileData} errors={errors} />,
              key: fileGuid,
              children: appendChildren(fileGuid),
            };
          })
          .filter(Boolean);
        return treeData;
      }
    ),
    selectedKey: createSelector(
      (state) => state.fileList?.activeFile || "",
      (activeFile) => activeFile
    ),
    allFiles: createSelector(
      (state) => state.fileList?.files || [],
      (state) => state.fileList?.childrenMap || {},
      (files, childrenMap) => {
        return [
          ...new Set(
            Object.keys(childrenMap).flatMap((key) => [
              key,
              ...childrenMap[key],
            ])
          ),
        ];
      }
    ),
  },
  actions: {
    onDrop: (data) => async (dispatch, module) =>
      onFileDrop({
        dragKey: data.dragNode.key,
        dropKey: data.node.key,
        module,
      }),
    onSelect: (key) => async (dispatch, module) => {
      if (!key[0]) {
        return;
      }
      const activeFile = key[0];
      const fileType = module.state.fileList?.entities?.[activeFile]?.type;

      if (fileType === "child-manifest") {
        dispatch({
          type: module.actions.editor.switchMode,
          mode: "editor",
        });
      } else {
        dispatch({
          type: module.actions.editor.switchMode,
          mode: "form",
        });
      }

      // this validates the tier we're about to switch from
      const currentActiveFile = module.state.fileList?.activeFile;
      const deletedFiles = module.state.fileList?.deletedFiles || [];
      const deletedChildren = (
        module.state.fileList?.deletedChildren || []
      ).map((child) => child.guid);

      if (![...deletedFiles, ...deletedChildren].includes(currentActiveFile)) {
        await store.dispatch(module.form.actions.validateForm());
      }

      dispatch({
        type: module.actions.fileList?.onFileSelect,
        fileGuid: key[0],
      });

      await store.dispatch(module.form.actions.init({ guid: key[0] }));

      // revalidate again if it was previously validated and errors were found
      const hasErrorsFromPreviousValidation =
        module.state.validations[key[0]]?.length > 0;

      if (hasErrorsFromPreviousValidation) {
        await store.dispatch(module.form.actions.validateForm());
      }
    },
  },
});

export default fileListConnector;
